import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Card } from "reactstrap";
import { SwapSpinner, ImpulseSpinner } from "react-spinners-kit";
import { useNavigate, useParams } from "react-router";
import { images } from "../../constants";
import axios from "axios";
import config from "../../config";
import { useDispatch } from "react-redux";
import {
  resetRoot,
  setApplicationId,
  setCompanyId,
  setCompanyPack,
  setToken,
  setUserId,
  setUserRole,
} from "../../store/reducers/root/rootSlice";
import {
  resetUser,
  setApplication,
  setCompany,
  setPermissions,
  setUser,
  setUserCompanies,
} from "../../store/reducers/user/userSlice";
import { UserTypes } from "../../util/context";
import {
  fetchAllApplications,
  resetApplications,
} from "../../store/reducers/applications/applicationsSlice";
import {
  fetchAllPermissions,
  resetPermissionsList,
} from "../../store/reducers/permissions-list/permissionsListSlice";
import { resetClientsList } from "../../store/reducers/clients-list/clientsSlice";
import { resetCategories } from "../../store/reducers/categories/categoriesSlice";
import { resetCompaniesList } from "../../store/reducers/companies-list/companiesSlice";
import { resetNotifs } from "../../store/reducers/messages-notifs/messagesNotifsSlice";
import { resetUtil } from "../../store/reducers/utils/utilSlice";
const { API_URL, APPLICATION_ID } = config[process.env.NODE_ENV];

const RedirectVerification = ({}) => {
  let { token, user_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [msg, setMsg] = useState("Nous traitons votre demande de redirection");

  const verifyToken = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/whitelabels/redirectionRequest/verify`,
        {
          token,
          user_id,
        }
      );
      console.log("response", response);
      if (
        response.data &&
        response.data.auth &&
        response.data.token &&
        response.data.user
      ) {
        setMsg(
          "Authentification réussie, vous serez redirigé vers la page d'accueil"
        );
        const { data } = response;
        const { token } = data;
        const { companies, ...user } = data.user;
        const { application, permissions } = user;
        if (application.id !== APPLICATION_ID) {
          setMsg(
            "Erreur d'authentification, vous serez redirigé vers la page de connexion"
          );
          setTimeout(() => {
            navigate("/login");
          }, 5000);
          return;
        }
        dispatch(setUserId(user.id));
        dispatch(setUser(user));
        dispatch(setApplicationId(application.id));
        dispatch(setApplication(application));
        companies && dispatch(setCompanyId(companies[0].id));
        companies && dispatch(setCompanyPack(companies[0].pack));
        companies && dispatch(setCompany(companies[0]));
        companies && dispatch(setUserCompanies(companies));
        permissions && dispatch(setPermissions(permissions));
        dispatch(setToken(token));
        dispatch(setUserRole(user.role));
        if (user.role !== UserTypes.Client) {
          dispatch(fetchAllApplications());
          dispatch(fetchAllPermissions());
        }

        setTimeout(() => {
          navigate("/");
        }, 5000);
      } else {
        setMsg(
          "Erreur d'authentification, vous serez redirigé vers la page de connexion"
        );
        setTimeout(() => {
          navigate("/login");
        }, 5000);
      }
    } catch (error) {
      console.log("verifying redirection token error : ", error);
      setMsg(
        "Erreur d'authentification, vous serez redirigé vers la page de connexion"
      );
      dispatch(resetRoot());
      dispatch(resetApplications());
      dispatch(resetCategories());
      dispatch(resetClientsList());
      dispatch(resetCompaniesList());
      dispatch(resetPermissionsList());
      dispatch(resetUser());
      dispatch(resetNotifs());
      dispatch(resetUtil());
      setTimeout(() => {
        navigate("/login");
      }, 5000);
    }
  };

  const checkCreds = async () => {
    if (token && user_id) {
      setTimeout(async () => {
        await verifyToken();
      }, 2000);
    } else {
      return navigate("/login");
    }
  };

  useEffect(() => {
    checkCreds();
  }, []);

  return (
    <Card className="spinner-card">
      <img className="spinner-logo" src={images.logo} alt="" />
      <div className="spinner-msg">
        <div className="spinner-msg_main">
          <p>{msg}</p>
        </div>
        <div className="spinner-msg_secondary">
          <p>Veuillez patienter </p>
          <ImpulseSpinner frontColor="#f5cf00" backColor="#000" />
        </div>
      </div>
    </Card>
  );
};

export default RedirectVerification;
