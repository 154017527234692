import React, {useEffect, useState} from "react";

import "./style.scss";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Tooltip,
} from "reactstrap";
import {colors, data} from "../../constants";
import {useNavigate, useParams} from "react-router-dom";
import config from "../../config";
import {useSelector} from "react-redux";
import axios from "axios";
import {ErrorLogger} from "../../util/errorLogger";
import {Company, IIndemnity, IVehicule, User} from "../../interfaces";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import vehiculeIconAdd from "../../assets/images/svg/vehicule-icon.svg";
import vehiculeIconGreen from "../../assets/images/svg/vehicule-green-icon.svg";
import CalenderIconBlue from "../../assets/images/svg/calender-icon-blue.svg";

import {BootyPagination} from "../../components/table/pagination";
import {
  RiAddFill,
  RiCalendar2Line,
  RiCarFill,
  RiDownload2Fill,
  RiInformationLine,
  RiPinDistanceFill,
  RiUser3Line,
} from "react-icons/ri";

import moment from "moment";
import "moment/locale/fr";
import Select from "react-select";
import {
  HORSE_POWERS,
  OptionType,
  UserTypes,
  VEHICULE_TYPES,
} from "../../util/context";
import VehiculeBanner from "./VehiculeBanner";
import DataTable from "react-data-table-component";
import {useIndemnity} from "../../util/hooks/useIndemnity";
import ModalBanner from "./ModalBanner";
import AttacheIcon from "../../assets/AttacheIcon";
import {IoMdCreate} from "react-icons/io";
import {BsTrashFill} from "react-icons/bs";
import IconPlusYellow from "../../assets/images/svg/puls-icon-yellow.svg";
import {useFormatter} from "../../util/hooks/useFormatter";
import CustomTooltip from "../../components/Tooltip";

import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import useAxios from "../../util/hooks/useAxios";
import {toast, ToastContainer} from "react-toastify";
import FileViewer from "../../components/FileViewer";
import {deleteFile} from "../ClientInfo/InformationClient";
import FilePicker from "../../components/FilePicker";
import Tabs from "../../components/Tabs";
import ArchivedIndemnity from "./ArchivedIndemnity";
import BannerTop from "../../components/Banner/BannerTop";
import {SiPypy} from "react-icons/si";
import UserInfo from "../../components/user-info/UserInfo";
import CrownIcon from "../../assets/CrownIcon";
import {ReactSVG} from "react-svg";
registerLocale("fr", fr);
export interface TransactionsProps {
  context?: string;
}

const {API_URL} = config[process.env.NODE_ENV];

type SearchIndemnitiesFormValues = {
  dateFrom?: string | null;
  dateTo?: string | null;
  vehiculeType?: OptionType | null;
  indemVehicule?: OptionType | null;
};

type SearchVehicvulesFormValues = {
  name: string | null;
  hp: OptionType | null;
};

type CreateVehiculeFormValues = {
  name: string | null;
  type: OptionType | null;
  hp: OptionType | null;
  gray_card?: any;
};

type CreateIndemnitiesFormValues = {
  distance: number | null;
  month: string | null;
  vehicule: OptionType | null;
  vehiculeType: OptionType | null;
};

type EditVehiculeFormValues = {
  edit_name: string | null;
  edit_type: OptionType | null;
  edit_hp: OptionType | null;
  edit_gray_card?: any;
};

type EditIndemnitiesFormValues = {
  edit_distance: number | null;
  edit_month: string | null;
  edit_vehicule: OptionType | null;
  edit_vehiculeType: OptionType | null;
};

const Indemnity = () => {
  const [selectedTab, setSelectedTab] = useState<number>(1);
  const [managementModal, setManagementModal] = useState<{
    status: boolean;
    vehicule?: string;
  }>({status: false, vehicule: ""});
  const [addVehiculeModal, setCreateVehiculeModal] = useState<boolean>(false);
  const [crudLoading, setCrudLoading] = useState<boolean>(false);
  const [addIndemnityeModal, setCreateIndemnityModal] =
    useState<boolean>(false);
  const [editVehiculeModal, setEditVehiculeModal] = useState<boolean>(false);
  const [editIndemnityModal, setEditIndemnityModal] = useState<boolean>(false);
  const [deleteAssetModal, setDeleteAssetModal] = useState<{
    status: boolean;
    type?: string;
  }>({status: false, type: ""});
  const [loading, setLoading] = useState<boolean>(false);
  const [indemnities, setIndemnities] = useState<Array<IIndemnity>>([]);
  const [archivedIndemnities, setArchivedIndemnities] = useState<
    Array<IIndemnity>
  >([]);
  const [vehicules, setVehicules] = useState<Array<IVehicule>>([]);
  const [singleVehicule, setSingleVehicule] = useState<IVehicule | null>();
  const [singleIndemnity, setSingleIndemnity] = useState<IIndemnity | null>();
  const [url, setUrl] = useState<string | null>(null);
  const [viewModal, setViewModal] = useState<boolean>(false);

  const [pending1, setPending1] = useState<boolean>(true);
  const [pending2, setPending2] = useState<boolean>(true);
  const [pending3, setPending3] = useState<boolean>(true);

  const {calculateAmount} = useIndemnity();
  const resetIndemnitySearchForm = async () => {
    searchIndemnityReset({
      dateFrom: null,
      dateTo: null,
      vehiculeType: null,
      indemVehicule: null,
    });
    await getIndemnities();
  };

  const resetVehiculeCreateEditForm = async (
    payload: null | EditVehiculeFormValues = null
  ) => {
    if (!payload) {
      createVehiculeReset({
        name: null,
        hp: null,
        type: null,
        gray_card: null,
      });
    } else {
      editVehiculeReset({
        edit_name: payload.edit_name,
        edit_hp: payload.edit_hp,
        edit_type: payload.edit_type,
        // edit_gray_card: payload.edit_gray_card,
      });
      setVehiculeGrayCard(payload.edit_gray_card);
    }
    await getVehicules();
  };

  const resetIndemnityCreateEditForm = async (
    payload: null | EditIndemnitiesFormValues = null
  ) => {
    if (!payload) {
      createIndemnityReset({
        distance: null,
        month: null,
        vehicule: null,
        vehiculeType: null,
      });
    } else {
      editIndemnityReset({
        edit_distance: payload.edit_distance,
        edit_month: payload.edit_month,
        edit_vehicule: payload.edit_vehicule,
        edit_vehiculeType: VEHICULE_TYPES.find(
          (elt) => elt.value === payload.edit_vehiculeType?.value
        )!,
      });
    }
    await getVehicules();
  };

  const resetVehiculeSearchForm = async () => {
    searchVehiculeReset({
      hp: null,
      name: null,
    });
    await getVehicules();
  };

  const creds = useSelector(
    (state: {root: object; user: object}) => state.root
  ) as {user_id: string; company_id: string; token: string; role: string};

  const {user, company: userCompany} = useSelector(
    (state: {root: object; user: object; company: object}) => state.user
  ) as {user: User; company: Company};

  const {newNotif} = useSelector(
    (state: {messagesNotifs: object}) => state.messagesNotifs
  ) as {newNotif: boolean};

  const {id} = useParams();
  const {setDecimalDigits} = useFormatter();

  const indemnitiesColumns: any = [
    {
      name: "Véhicule",
      selector: (row: any) => row.vehicule.name.toUpperCase(),
      sortable: true,
    },
    {
      name: "Type de véhicule",
      selector: (row: any) =>
        VEHICULE_TYPES.find((elt) => elt.value === row.vehicule.type)?.label,
      sortable: true,
    },
    {
      name: "Mois",
      selector: (row: any) =>
        moment(row.from).format("MMMM YYYY").toUpperCase(),
    },
    {
      name: "Puissance",
      selector: (row: any) => `${row.vehicule.hp} CV`,
    },
    {
      name: "Distance",
      selector: (row: any) => row.kms + " Km",
      sortable: true,
    },
    // {
    //   name: "Montant",
    //   selector: (row: any) => `${setDecimalDigits(row.amount)} €`,
    //   sortable: true,
    // },
    {
      name: "Action",
      button: true,
      cell: (row: IIndemnity) => (
        <div className="table-action">
          <button
            className="btn btn-blue"
            onClick={() => onEditToggle(row, "Indemnity")}
          >
            <IoMdCreate />
          </button>
          <button
            className="btn btn-red"
            onClick={() => onDeleteToggle(row, "Indemnity")}
          >
            <BsTrashFill />
          </button>
        </div>
      ),
    },
  ];

  const vehiculesColumns: any = React.useMemo(
    () => [
      {
        name: "Véhicule",
        selector: (row: IVehicule) => row.name,
        sortable: true,
      },
      {
        name: "Type de véhicule",
        selector: (row: IVehicule) =>
          VEHICULE_TYPES.find((elt) => elt.value === row.type)?.label,
        sortable: true,
      },
      {
        name: "Puissance",
        selector: (row: IVehicule) =>
          HORSE_POWERS.find((elt) => elt.value === row.hp)?.label,
        omit: managementModal.vehicule === "moped",
      },
      {
        name: "Action",
        button: true,
        cell: (row: IVehicule) => (
          <div className="table-action">
            <button
              className="btn btn-blue"
              onClick={() => onEditToggle(row, "Vehicule")}
            >
              <IoMdCreate />
            </button>
            <button
              className="btn btn-red"
              onClick={() => onDeleteToggle(row, "Vehicule")}
            >
              <BsTrashFill />
            </button>
          </div>
        ),
      },
    ],
    [managementModal]
  );

  const onEditToggle = (row: IIndemnity | IVehicule, type: string) => {
    if (type === "Vehicule") {
      setSingleVehicule(row);
      resetVehiculeCreateEditForm({
        edit_name: (row as IVehicule).name as string,
        edit_type: VEHICULE_TYPES.find(
          (elt) => elt.value === (row as IVehicule).type
        )!,
        edit_hp: HORSE_POWERS.find(
          (elt) => elt.value === (row as IVehicule).hp
        )!,
        edit_gray_card: (row as IVehicule).gray_card,
      });
      setEditVehiculeModal(true);
    }

    if (type === "Indemnity") {
      const foundVeh = vehicules.find(
        (elt) => elt.id === (row as IIndemnity).vehicule_id
      );
      setSingleIndemnity(row as IIndemnity);
      resetIndemnityCreateEditForm({
        edit_distance: (row as IIndemnity).kms as number,
        edit_month: moment((row as IIndemnity).from).format("YYYY-MM"),
        edit_vehicule: {
          label: foundVeh?.name as string,
          value: foundVeh?.id as string,
        },
        edit_vehiculeType: VEHICULE_TYPES.find(
          (elt) => elt.value === foundVeh?.type
        )!,
      });
      setEditIndemnityModal(true);
    }
  };

  const [vehiculeGrayCard, setVehiculeGrayCard] = useState<any>();

  const onDeleteToggle = (row: IIndemnity | IVehicule, type: string) => {
    if (type === "Vehicule") {
      setSingleVehicule(row);
    }

    if (type === "Indemnity") {
      setSingleIndemnity(row as IIndemnity);
    }
    setDeleteAssetModal({status: true, type});
  };
  let api = useAxios();

  const navigate = useNavigate();
  const getIndemnities = async (vehiculeId: string = "") => {
    try {
      let where: any = {company_id: id || userCompany.id};
      if (vehiculeId !== "") {
        where = {
          ...where,
          vehiculeId,
        };
      }
      const {data} = await api.post(
        `/api/Indemnity/All`,
        {
          where,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      setIndemnities(
        data.data
          .sort((a: {from: string}, b: {from: string}) => {
            return moment(a.from).diff(moment(b.from));
          })
          .filter(
            (elt: {from: moment.MomentInput}) =>
              moment(elt.from).valueOf() >=
              moment().startOf("year").set({hour: 1, minute: 0}).valueOf()
          )
      );

      setArchivedIndemnities(
        data.data
          .sort((a: {from: number}, b: {from: number}) => {
            return moment(b.from).diff(moment(a.from));
          })
          .filter(
            (elt: {to: moment.MomentInput}) =>
              moment(elt.to).format("YYYY-MM-DD hh:mm").valueOf() <
              moment().startOf("year").format("YYYY-MM-DD hh:mm").valueOf()
          )
      );

      setPending1(false);
      setPending2(false);

      return data.data
        .filter(
          (elt: {from: moment.MomentInput}) =>
            moment(elt.from).format("YYYY-MM-DD hh:mm").valueOf() >=
            moment().startOf("year").format("YYYY-MM-DD hh:mm").valueOf()
        )
        .sort((a: {from: string}, b: {from: string}) => {
          return moment(b.from).diff(moment(a.from));
        });
    } catch (error: any) {
      setPending1(false);
      setPending2(false);
      ErrorLogger("getting indemnities", error);
    }
  };

  const getVehicules = async () => {
    try {
      const {data} = await api.post(
        `/api/Vehicule/All`,
        {
          where: {
            company_id: id || userCompany.id,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      setVehicules(data.data);

      return data.data;
    } catch (error: any) {
      ErrorLogger("getting indemnities", error);
    }
  };

  useEffect(() => {
    if (creds.token) {
      getIndemnities();
      getVehicules();
    }
  }, []);

  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string;
  } | null>(null);

  const {
    control: searchIndemnityControl,
    register: searchIndemnityRegister,
    handleSubmit: searchIndemnityHandleSubmit,
    reset: searchIndemnityReset,
    formState: {errors: searchIndemnityErrors},
  } = useForm<SearchIndemnitiesFormValues>({});

  const {
    watch: createIndemnityWatch,
    control: createIndemnitycontrol,
    register: createIndemnityRegister,
    handleSubmit: createIndemnityHandleSubmit,
    reset: createIndemnityReset,
    formState: {errors: createIndemnityErrors},
  } = useForm<CreateIndemnitiesFormValues>({});

  const {vehiculeType: _vehiculeType} = createIndemnityWatch();

  const {
    watch: editIndemnityWatch,
    control: editIndemnityControl,
    register: editIndemnityRegister,
    handleSubmit: editIndemnityHandleSubmit,
    reset: editIndemnityReset,
    formState: {errors: editIndemnityErrors},
  } = useForm<EditIndemnitiesFormValues>({});

  const {edit_vehicule: _edit_vehicule, edit_vehiculeType: _edit_vehiculeType} =
    editIndemnityWatch();

  const {
    control: searchVehiculeControl,
    register: searchVehiculeRegister,
    handleSubmit: searchVehiculeHandleSubmit,
    reset: searchVehiculeReset,
    formState: {errors: searchVehiculeErrors},
  } = useForm<SearchVehicvulesFormValues>({});

  const {
    watch,
    control: createVehiculeControl,
    register: createVehiculeRegister,
    handleSubmit: createVehiculeHandleSubmit,
    reset: createVehiculeReset,
    formState: {errors: createVehiculeErrors},
  } = useForm<CreateVehiculeFormValues>({});

  const {
    watch: editVehiculeWatch,
    control: editVehiculeControl,
    register: editVehiculeRegister,
    handleSubmit: editVehiculeHandleSubmit,
    reset: editVehiculeReset,
    formState: {errors: editVehiculeErrors},
  } = useForm<EditVehiculeFormValues>({});

  const {gray_card: _gray_card, type: _type} = watch();

  const {edit_gray_card: _edit_gray_card, edit_type: _edit_type} =
    editVehiculeWatch();

  const searchIndemnities: SubmitHandler<SearchIndemnitiesFormValues> = async (
    form: SearchIndemnitiesFormValues
  ) => {
    try {
      setLoading(true);
      let payload: any = {
        company_id: id || userCompany.id,
      };
      if (form.dateFrom)
        payload.from = {
          gte: moment(form.dateFrom).format("YYYY-MM-DD hh:mm"),
        };
      if (form.dateTo)
        payload.to = {
          lte: moment(form.dateTo).format("YYYY-MM-DD hh:mm"),
        };
      if (form.vehiculeType)
        payload.vehicule = {
          type: form.vehiculeType.value,
        };

      if (form.indemVehicule)
        payload.vehicule = {
          id: form.indemVehicule.value,
        };

      const {data} = await api.post(
        `/api/Indemnity/All`,
        {
          where: {
            ...payload,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setIndemnities(data.data);
      setLoading(false);
    } catch (error: any) {
      ErrorLogger("searching indemnities", error);
    }
  };

  const searchVehicules: SubmitHandler<SearchVehicvulesFormValues> = async (
    form: SearchVehicvulesFormValues
  ) => {
    try {
      setLoading(true);
      let payload: any = {
        company_id: id || userCompany.id,
      };
      if (form.name)
        payload.name = {
          iLike: `%${form.name}%`,
        };
      if (form.hp) payload.hp = form.hp.value;

      const {data} = await api.post(
        `/api/Vehicule/All`,
        {
          where: {
            ...payload,
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      setVehicules(data.data);
      setLoading(false);
    } catch (error: any) {
      ErrorLogger("searching vehicules", error);
    }
  };

  const createVehicule: SubmitHandler<CreateVehiculeFormValues> = async (
    form: CreateVehiculeFormValues
  ) => {
    try {
      setErrorMessage(null);
      if (!form.name || !form.type || !vehiculeGrayCard) {
        setErrorMessage({
          type: "not_valid",
          message: "Veuillez remplir tous les champs nécessaires du formulaire",
        });
        return;
      }
      setCrudLoading(true);
      const formData = new FormData();

      formData.append("company_id", id || userCompany.id);
      formData.append("name", form.name);
      form.hp && formData.append("hp", form.hp.value as string);
      formData.append("type", form.type.value as string);

      if (vehiculeGrayCard && !vehiculeGrayCard.key) {
        formData.append("gray_card", vehiculeGrayCard);
      }

      const {data} = await api.post(`/api/Vehicule/Create`, formData, {
        headers: {
          "x-access-token": creds.token,
        },
      });
      resetVehiculeCreateEditForm();
      await getVehicules();
      setCrudLoading(false);
      setCreateVehiculeModal(false);
      setVehiculeGrayCard(null);
      if (data.id) {
        toast.dismiss();
        toast.success("Votre ajout a été effectué avec succès!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error: any) {
      ErrorLogger("creating vehicule", error);
    }
  };

  const editVehicule: SubmitHandler<EditVehiculeFormValues> = async (
    form: EditVehiculeFormValues
  ) => {
    try {
      setCrudLoading(true);
      const formData = new FormData();
      formData.append("id", singleVehicule?.id as string);

      if (form.edit_name !== singleVehicule?.name) {
        formData.append("name", form.edit_name as string);
      }
      if (form.edit_hp?.value !== singleVehicule?.hp) {
        formData.append("hp", form.edit_hp?.value as string);
      }
      if (form.edit_type?.value !== singleVehicule?.type) {
        formData.append("type", form.edit_type?.value as string);
      }
      if (vehiculeGrayCard && !vehiculeGrayCard.key) {
        formData.append("gray_card", vehiculeGrayCard);
      }

      const {data} = await api.post(`/api/Vehicule/Update`, formData, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      resetVehiculeCreateEditForm();
      const updatedVehicules = (await getVehicules()) as IVehicule[];
      if (
        updatedVehicules.length > 0 &&
        form.edit_hp?.value !== singleVehicule?.hp
      ) {
        const indemnitiesArr = (await getIndemnities(
          singleVehicule?.id!
        )) as IIndemnity[];

        if (indemnitiesArr.length > 0) {
          for (let indem of indemnitiesArr) {
            const updatedVeh = {
              edit_distance: indem?.kms!,
              edit_month: indem?.from!,
              edit_vehicule: {
                value: singleVehicule?.id,
                label: singleVehicule?.name,
              },
              edit_vehiculeType: VEHICULE_TYPES.find(
                (elt) => elt.value === singleVehicule?.type
              )!,
            };

            await editIndemnityLogic(
              indem?.id!,
              updatedVeh,
              true,
              updatedVehicules
            );
          }
        }
      }
      setCrudLoading(false);
      setEditVehiculeModal(false);
      setSingleVehicule(null);
      setVehiculeGrayCard(null);
      if (data.updated.length > 0) {
        toast.dismiss();
        toast.success("Votre modification a été effectuée avec succès!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error: any) {
      ErrorLogger("editing vehicule", error);
    }
  };

  const deleteAsset = async (asset: IVehicule | IIndemnity, type: string) => {
    try {
      setCrudLoading(true);
      const {data} = await api.post(
        `/api/${type}/deleteWhere`,
        {
          where: {
            id: [asset?.id],
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      if (type === "Indemnity") {
        await getIndemnities();
      }

      if (type === "Vehicule") {
        await getVehicules();
      }
      setCrudLoading(false);
      setDeleteAssetModal({status: false, type: ""});
      if (data.msg && data.msg.toLowerCase() === "ok") {
        toast.dismiss();
        toast.success("Votre suppression a été effectuée avec succès!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error: any) {
      ErrorLogger("editing vehicule", error);
    }
  };

  const createIndemnity: SubmitHandler<CreateIndemnitiesFormValues> = async (
    form: CreateIndemnitiesFormValues
  ) => {
    try {
      setErrorMessage(null);
      if (!form.vehicule || !form.month || !form.distance) {
        setErrorMessage({
          type: "not_valid",
          message: "Veuillez remplir tous les champs nécessaires du formulaire",
        });
        return;
      }
      setCrudLoading(true);

      if (
        indemnities.find(
          (elt) =>
            moment(elt.to).get("month") ===
              moment(form.month).endOf("month").get("month") &&
            moment(elt.to).get("year") ===
              moment(form.month).endOf("month").get("year") &&
            elt.vehiculeId === form.vehicule?.value
        ) ||
        archivedIndemnities.find(
          (elt) =>
            moment(elt.to).get("month") ===
              moment(form.month).endOf("month").get("month") &&
            moment(elt.to).get("year") ===
              moment(form.month).endOf("month").get("year") &&
            elt.vehiculeId === form.vehicule?.value
        )
      ) {
        setErrorMessage({
          type: "not_valid",
          message:
            "Il existe déjà une indemnité pour le même mois et le même véhicule.",
        });
        setCrudLoading(false);
        return;
      }
      const formData = new FormData();

      formData.append("company_id", id || userCompany.id);
      formData.append("vehicule_id", form.vehicule.value as string);
      formData.append("kms", String(form.distance));
      formData.append(
        "from",
        moment(form.month).startOf("month").format("YYYY-MM-DD hh:mm") as string
      );
      formData.append(
        "to",
        moment(form.month).endOf("month").format("YYYY-MM-DD hh:mm") as string
      );

      // const prevIndemnities = indemnities.filter((elt) => {
      //   return (
      //     elt.vehiculeId === form.vehicule?.value &&
      //     moment(elt.to).get("month") <
      //       moment(form.month).endOf("month").get("month") &&
      //     moment(elt.to).get("year") ===
      //       moment(form.month).endOf("month").get("year")
      //   );
      // });

      // const amount = calculateAmount(
      //   vehicules.find((elt) => elt.id === form.vehicule?.value)!,
      //   prevIndemnities,
      //   parseFloat(String(form.distance!))
      // );

      formData.append("amount", "0");

      const {data} = await api.post(`/api/Indemnity/Create`, formData, {
        headers: {
          "x-access-token": creds.token,
        },
      });
      resetIndemnityCreateEditForm();
      const indemnitiesArr = (await getIndemnities()) as IIndemnity[];
      setCrudLoading(false);
      if (data.id) {
        const nextIndemnities = indemnitiesArr.filter((elt) => {
          return (
            elt.vehiculeId === form.vehicule?.value &&
            moment(elt.from).get("month") >
              moment(form.month).startOf("month").get("month") &&
            moment(elt.to).get("year") ===
              moment(form.month).endOf("month").get("year")
          );
        });

        if (nextIndemnities.length > 0) {
          // for (let ind of nextIndemnities.reverse()) {
          //   // const _prevInds = indemnitiesArr.filter((elt) => {
          //   //   return (
          //   //     elt.vehiculeId === ind.vehiculeId &&
          //   //     moment(elt.from).get("month") <
          //   //       moment(ind.from).startOf("month").get("month") &&
          //   //     moment(elt.from).get("year") ===
          //   //       moment(ind.from).endOf("month").get("year")
          //   //   );
          //   // });

          //   // await api.post(
          //   //   `/api/Indemnity/Update`,
          //   //   {
          //   //     id: ind?.id,
          //   //     amount: calculateAmount(
          //   //       vehicules.find((elt) => elt.id === form.vehicule?.value)!,
          //   //       _prevInds,
          //   //       parseFloat(String(ind?.kms))
          //   //     ),
          //   //   },
          //   //   {
          //   //     headers: {
          //   //       "x-access-token": creds.token,
          //   //     },
          //   //   }
          //   // );
          // }
          await getIndemnities();
        }
        toast.dismiss();
        toast.success("Votre ajout a été effectué avec succès!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      setCreateIndemnityModal(false);
    } catch (error: any) {
      ErrorLogger("creating indemnity", error);
    }
  };

  const editIndemnityLogic = async (
    indemId: string,
    form: EditIndemnitiesFormValues,
    force: boolean = false,
    updatedVehicules: IVehicule[] = []
  ) => {
    const formData = new FormData();
    formData.append("id", indemId as string);

    if (
      moment(form.edit_month).startOf("month").format("YYYY-MM-DD hh:mm") !==
        singleIndemnity?.from ||
      force === true
    ) {
      formData.append(
        "from",
        moment(form.edit_month)
          .startOf("month")
          .format("YYYY-MM-DD hh:mm") as string
      );
      formData.append(
        "to",
        moment(form.edit_month)
          .endOf("month")
          .format("YYYY-MM-DD hh:mm") as string
      );
    }
    if (
      form.edit_vehicule?.value !== singleIndemnity?.vehicule.id ||
      force === true
    ) {
      formData.append("vehicule_id", form.edit_vehicule?.value as string);
    }

    // const prevIndemnities = indemnities.filter((elt) => {
    //   return (
    //     elt.vehiculeId === form.edit_vehicule?.value &&
    //     moment(elt.to).get("month") <
    //       moment(form.edit_month).endOf("month").get("month") &&
    //     moment(elt.to).get("year") ===
    //       moment(form.edit_month).endOf("month").get("year")
    //   );
    // });

    if (
      String(form.edit_distance) !== String(singleIndemnity?.kms) ||
      force === true
    ) {
      formData.append("kms", String(form.edit_distance));
      // const amount = calculateAmount(
      //   (updatedVehicules.length > 0 ? updatedVehicules : vehicules).find(
      //     (elt) => elt.id === form.edit_vehicule?.value
      //   )!,
      //   prevIndemnities,
      //   parseFloat(String(form.edit_distance!))
      // );

      // formData.append("amount", String(amount));
    }

    const {data} = await api.post(`/api/Indemnity/Update`, formData, {
      headers: {
        "x-access-token": creds.token,
      },
    });

    resetIndemnityCreateEditForm();
    const indemnitiesArr = (await getIndemnities()) as IIndemnity[];

    if (data.updated.length > 0) {
      const nextIndemnities: IIndemnity[] = indemnitiesArr.filter((elt) => {
        return (
          elt.vehiculeId === form.edit_vehicule?.value &&
          moment(elt.from).get("month") >
            moment(form.edit_month).startOf("month").get("month") &&
          moment(elt.to).get("year") ===
            moment(form.edit_month).endOf("month").get("year")
        );
      });

      if (nextIndemnities.length > 0) {
        for (let ind of nextIndemnities.reverse()) {
          const _prevInds = indemnitiesArr.filter((elt) => {
            return (
              elt.vehiculeId === ind.vehiculeId &&
              moment(elt.from).get("month") <
                moment(ind.from).startOf("month").get("month") &&
              moment(elt.from).get("year") ===
                moment(ind.from).endOf("month").get("year")
            );
          });
          await api.post(
            `/api/Indemnity/Update`,
            {
              id: ind?.id,
              amount: calculateAmount(
                (updatedVehicules.length > 0
                  ? updatedVehicules
                  : vehicules
                ).find((elt) => elt.id === form.edit_vehicule?.value)!,
                _prevInds,
                parseFloat(String(ind?.kms))
              ),
            },
            {
              headers: {
                "x-access-token": creds.token,
              },
            }
          );
        }
        await getIndemnities();
      }
      toast.dismiss();
      toast.success("Votre modification a été effectuée avec succès!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const editIndemnity: SubmitHandler<EditIndemnitiesFormValues> = async (
    form: EditIndemnitiesFormValues
  ) => {
    try {
      setCrudLoading(true);
      await editIndemnityLogic(singleIndemnity?.id!, form);
      setEditIndemnityModal(false);
      setCrudLoading(false);
    } catch (error: any) {
      ErrorLogger("editing indemnity", error);
    }
  };

  // search indemnity form
  const {ref: dateToRef, ...dateTo} = searchIndemnityRegister("dateTo");
  const {ref: dateFromRef, ...dateFrom} = searchIndemnityRegister("dateFrom");

  // create indemnity form
  const {ref: monthRef, ...month} = createIndemnityRegister("month");
  const {ref: distanceRef, ...distance} = createIndemnityRegister("distance");

  // edit indemnity form
  const {ref: edit_monthRef, ...edit_month} =
    editIndemnityRegister("edit_month");
  const {ref: edit_distanceRef, ...edit_distance} =
    editIndemnityRegister("edit_distance");

  // create vehicule form
  const {ref: nameRef, ...name} = createVehiculeRegister("name");
  const {ref: gray_cardRef, ...gray_card} = createVehiculeRegister("gray_card");

  // edit vehicule form
  const {ref: edit_nameRef, ...edit_name} = editVehiculeRegister("edit_name");
  const {ref: edit_gray_cardRef, ...edit_gray_card} =
    editVehiculeRegister("edit_gray_card");

  // search vehicule form
  const {ref: searchNameRef, ...searchName} = searchVehiculeRegister("name");

  const handleGrayCardCreationCahange = (event: {target: {files: any}}) => {
    setVehiculeGrayCard(event.target.files![0]);
  };

  const handleGrayCardEditCahange = (event: {target: {files: any}}) => {
    setVehiculeGrayCard(event.target.files![0]);
  };

  return (
    <>
      <div className="page">
        {user.id && user.role === UserTypes.Client ? (
          <>
            <div
              className={`top-content indemnity-selector-wrapper ${
                creds.role === UserTypes.Client ? "client-resize-header" : ""
              }`}
            >
              <Row>
                <Col lg={8} md={12} className="blockBanner">
                  <BannerTop banner={data.indemnity} />
                </Col>
                <Col lg={4} md={12}>
                  <Card className="blockUser">
                    <Button
                      className="btn-icon btn-icon-primary"
                      color="light"
                      onClick={() => navigate("/tickets")}
                    >
                      {newNotif && <Badge color="primary"> </Badge>}
                      <CrownIcon stroke={colors.primaryColor} />
                    </Button>
                    <UserInfo user={user} />
                    <Button
                      className="btn-icon btn-icon-primary"
                      color="light"
                      onClick={() =>
                        navigate({
                          pathname: "/profil/client",
                          // search: "?page=1&tab=1",
                        })
                      }
                    >
                      <RiUser3Line />
                    </Button>
                  </Card>
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <></>
        )}
        <Card className="card-Table table-primary first__card">
          <div className="first__card__header">
            <h2>Indemnité kilométrique</h2>

            <span style={{display: "flex", gap: "12px"}}>
              <Button
                color="primary"
                type="submit"
                onClick={() => {
                  setCreateVehiculeModal(true);
                }}
              >
                <span className="vh-icon">
                  <img src={vehiculeIconAdd} alt="icon" />
                </span>
                Ajouter un véhicule
              </Button>
              <Button
                color="retour-btn btn btn-secondary"
                type="button"
                href="https://www.impots.gouv.fr/simulateur-bareme-kilometrique"
                target="_blank"
                style={{display: "flex", alignItems: "center"}}
              >
                Consulter le simulateur
              </Button>
            </span>
          </div>
          <div className="first__card__description mt-2">
            <p>
              <strong>NB</strong> : Ces barèmes sont calculés en fonction de la
              puissance du véhicule et du nombre de kilomètres parcourus.{" "}
              <strong>
                Ils prennent en compte notamment la dépréciation du véhicule,
                les frais de réparation et d'entretien, les dépenses de
                pneumatiques, la consommation de carburant et les primes
                d'assurances. De fait, les transactions afférentes à ces
                dépenses ne peuvent être doublement comptabilisées
              </strong>{" "}
              . Il est possible d'ajouter les intérêts d'emprunt en cas d'achat
              de véhicule à crédit, les frais de péages et les frais de
              stationnement.
            </p>
            <p>
              Ces frais de déplacement concernent en particulier le transport du
              domicile au lieu de travail et le transport pendant les heures de
              travail. Le client doit nous indiquer si le véhicule est un 2
              roues, une voiture ou un cyclomoteur (source :{" "}
              <span className="text-orange">
                <a href="https://www.service-public.fr/" target={"_blank"}>
                  https://www.service-public.fr/
                </a>
              </span>
              ) L'utilisation du barème kilométrique ne dispense pas d'apporter
              les justifications à l'administration fiscale.
            </p>
          </div>
        </Card>

        <div className="second__card">
          <Card className="card-vehics">
            <VehiculeBanner
              banner={data.car}
              callback={() =>
                setManagementModal({status: true, vehicule: "car"})
              }
              context={vehicules.filter((elt) => elt.type === "car").length}
            />
          </Card>

          <Card className="card-vehics">
            <VehiculeBanner
              banner={data.motorcycle}
              callback={() =>
                setManagementModal({
                  status: true,
                  vehicule: "motorcycle",
                })
              }
              context={
                vehicules.filter((elt) => elt.type === "motorcycle").length
              }
            />
          </Card>

          <Card className="card-vehics">
            <VehiculeBanner
              banner={data.moped}
              callback={() =>
                setManagementModal({status: true, vehicule: "moped"})
              }
              context={vehicules.filter((elt) => elt.type === "moped").length}
            />
          </Card>
        </div>
        <Card className="card-filter">
          <form onSubmit={searchIndemnityHandleSubmit(searchIndemnities)}>
            <Row className="align-items-end">
              <Col lg={9} md={12}>
                <div className="filterInner">
                  <Row>
                    <Col md={3}>
                      <FormGroup className="form-icon icon-end">
                        <Label for="dated">De</Label>
                        <Controller
                          control={searchIndemnityControl}
                          name="dateFrom"
                          render={({field}) => (
                            <DatePicker
                              placeholderText="Date de début"
                              onChange={(date: any) => field.onChange(date)}
                              selected={
                                field.value ? new Date(field.value) : null
                              }
                              className="form-control form-secondary"
                              locale="fr"
                              dateFormat="dd/MM/yyyy"
                            />
                          )}
                        />
                        {/* <Input
                          id="dated"
                          {...dateFrom}
                          innerRef={dateFromRef}
                          placeholder="Date de début"
                          type="date"
                          className="form-secondary"
                        /> */}
                        <span className="icon icon-secondary icon-secondary-fill">
                          {/* <img src={CalenderIconBlue} alt="icon" /> */}
                          {<ReactSVG src={CalenderIconBlue} />}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup className="form-icon icon-end">
                        <Label for="datef">À</Label>
                        <Controller
                          control={searchIndemnityControl}
                          name="dateTo"
                          render={({field}) => (
                            <DatePicker
                              placeholderText="Date de fin"
                              onChange={(date: any) => field.onChange(date)}
                              selected={
                                field.value ? new Date(field.value) : null
                              }
                              className="form-control form-secondary"
                              locale="fr"
                              dateFormat="dd/MM/yyyy"
                            />
                          )}
                        />
                        {/* <Input
                          id="datef"
                          {...dateTo}
                          innerRef={dateToRef}
                          placeholder="Date de fin"
                          type="date"
                          className="form-secondary"
                        /> */}
                        <span className="icon icon-secondary icon-secondary-fill">
                          {/* <img src={CalenderIconBlue} alt="icon" /> */}
                          {<ReactSVG src={CalenderIconBlue} />}
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="exampleEmail">Type de véhicule</Label>
                        <Controller
                          name="vehiculeType"
                          control={searchIndemnityControl}
                          render={({field}) => (
                            <Select
                              {...field}
                              closeMenuOnSelect={true}
                              options={VEHICULE_TYPES}
                              classNamePrefix="select"
                              className="custom-select form-secondary"
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="exampleEmail">Véhicule</Label>
                        <Controller
                          name="indemVehicule"
                          control={searchIndemnityControl}
                          render={({field}) => (
                            <Select
                              {...field}
                              closeMenuOnSelect={true}
                              options={vehicules.reduce(
                                (acc, curr) => [
                                  ...acc,
                                  {
                                    label: curr.name as string,
                                    value: curr.id as string,
                                  },
                                ],
                                [] as {label: string; value: string}[]
                              )}
                              classNamePrefix="select"
                              className="custom-select form-secondary"
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={3} md={12}>
                <div className="actionsFilter mb-3">
                  <Button color="secondary" type="submit">
                    <span>Filtrer</span>
                  </Button>
                  <Button
                    color="secondary"
                    outline
                    type="button"
                    onClick={async () => await resetIndemnitySearchForm()}
                  >
                    <span>Réinitialiser</span>
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
        </Card>
        <section style={{paddingInline: "24px", marginTop: "24px"}}>
          <p>
            Tableau d'entrées de distance parcourue par véhicule :
            <br /> Notez bien que le calcul des IK sur le simulateur doit se
            faire sur une distance globale parcourue durant l'année entière
            (barème évolutif).
            <br /> De ce fait, veuillez prendre en compte ce point si des IK
            sont récupérés en cours d'année, il conviendra de faire les
            déductions adéquates.
          </p>
        </section>
        <Card className="card-Table table-primary">
          <div className="create-indem__button action-top-tabs">
            <Button
              color="primary"
              type="submit"
              onClick={() => {
                setCreateIndemnityModal(true);
              }}
            >
              <img src={IconPlusYellow} alt="icon" className="svg-warning" />
              Ajouter une indemnité kilométrique
            </Button>
          </div>
          <Tabs
            selectedTab={selectedTab}
            onClick={setSelectedTab}
            tabs={[
              {
                label: "Courant",
                index: 1,
                Component: () => (
                  <DataTable
                    columns={indemnitiesColumns}
                    noDataComponent={<p>Il n'y a aucun data à afficher</p>}
                    data={indemnities}
                    pagination
                    paginationComponent={(props) => {
                      const customProps = {...props, color: "primary"};
                      return <BootyPagination {...customProps} />;
                    }}
                    progressPending={pending1}
                    progressComponent={
                      <>
                        <Spinner color="secondary" type="grow" className="mx-1">
                          Loading...
                        </Spinner>
                        <Spinner color="secondary" type="grow" className="mx-1">
                          Loading...
                        </Spinner>
                        <Spinner color="secondary" type="grow" className="mx-1">
                          Loading...
                        </Spinner>
                      </>
                    }
                  />
                ),
              },
              {
                label: "Archivées",
                index: 2,
                Component: () => (
                  <DataTable
                    columns={indemnitiesColumns}
                    noDataComponent={<p>Il n'y a aucun data à afficher</p>}
                    data={archivedIndemnities}
                    pagination
                    paginationComponent={(props) => {
                      const customProps = {...props, color: "primary"};
                      return <BootyPagination {...customProps} />;
                    }}
                    progressPending={pending2}
                    progressComponent={
                      <>
                        <Spinner color="secondary" type="grow" className="mx-1">
                          Loading...
                        </Spinner>
                        <Spinner color="secondary" type="grow" className="mx-1">
                          Loading...
                        </Spinner>
                        <Spinner color="secondary" type="grow" className="mx-1">
                          Loading...
                        </Spinner>
                      </>
                    }
                  />
                ),
              },
            ]}
            context={id}
          />
        </Card>
        <div className="openbtn text-center">
          {/*management modal*/}
          <Modal
            className="modal-secondary modal-dialog-centered modal-lg"
            isOpen={managementModal.status}
            toggle={async () => {
              setManagementModal({status: false, vehicule: ""});
              await getVehicules();
            }}
          >
            <ModalHeader
              className="no-header"
              toggle={async () => {
                setManagementModal({status: false, vehicule: ""});
                await getVehicules();
              }}
            >
              {/* {
                VEHICULE_TYPES.find(
                  (elt) => elt.value === managementModal.vehicule
                )?.label
              } */}
            </ModalHeader>

            <ModalBody>
              <div className="content-form-block">
                {managementModal.vehicule !== "" && (
                  <ModalBanner
                    banner={(data as any)[`${managementModal.vehicule!}`]}
                  />
                )}

                <Card className="card-filter">
                  <form onSubmit={searchVehiculeHandleSubmit(searchVehicules)}>
                    <Row className="align-items-end">
                      <Col lg={8} md={12}>
                        <div className="filterInner">
                          <Row>
                            <Col
                              md={managementModal.vehicule !== "moped" ? 6 : 12}
                            >
                              <FormGroup className="form-icon icon-end">
                                <Label for="name">
                                  {
                                    VEHICULE_TYPES.find(
                                      (elt) =>
                                        elt.value === managementModal.vehicule
                                    )?.label
                                  }
                                </Label>
                                <Input
                                  {...searchName}
                                  innerRef={searchNameRef}
                                  id="name"
                                  type="text"
                                  className="form-secondary"
                                />
                                <span className="icon icon-secondary">
                                  <RiCalendar2Line />
                                </span>
                              </FormGroup>
                            </Col>
                            {managementModal.vehicule !== "moped" && (
                              <Col md={6}>
                                <FormGroup>
                                  <Label for="exampleEmail">Puissance</Label>
                                  <Controller
                                    name="hp"
                                    control={searchVehiculeControl}
                                    render={({field}) => (
                                      <Select
                                        {...field}
                                        options={HORSE_POWERS}
                                        closeMenuOnSelect={true}
                                        classNamePrefix="select"
                                        className="custom-select form-secondary"
                                      />
                                    )}
                                  />
                                </FormGroup>
                              </Col>
                            )}
                          </Row>
                        </div>
                      </Col>
                      <Col lg={4} md={12}>
                        <div className="actionsFilter mb-3">
                          <Button color="secondary" type="submit">
                            <span>Filtrer</span>
                          </Button>
                          <Button
                            color="secondary"
                            outline
                            type="button"
                            onClick={async () =>
                              await resetVehiculeSearchForm()
                            }
                          >
                            <span>Réinitialiser</span>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </Card>
                <Card className="card-Table table-primary">
                  <DataTable
                    columns={vehiculesColumns}
                    data={vehicules.filter(
                      (elt) => elt.type === managementModal.vehicule
                    )}
                    noDataComponent={<p>Il n'y a aucun data à afficher</p>}
                    progressPending={loading}
                    progressComponent={
                      <>
                        <Spinner color="secondary" type="grow" className="mx-1">
                          Loading...
                        </Spinner>
                        <Spinner color="secondary" type="grow" className="mx-1">
                          Loading...
                        </Spinner>
                        <Spinner color="secondary" type="grow" className="mx-1">
                          Loading...
                        </Spinner>
                      </>
                    }
                    pagination
                    paginationComponent={(props) => {
                      const customProps = {...props, color: "primary"};
                      return <BootyPagination {...customProps} />;
                    }}
                  />
                </Card>
              </div>
            </ModalBody>
          </Modal>

          {/*add vehicule modal*/}
          <Modal
            className="modal-primary modal-dialog-centered"
            isOpen={addVehiculeModal}
            toggle={() => {
              setCreateVehiculeModal(false);
              resetVehiculeCreateEditForm();
              setVehiculeGrayCard(null);
            }}
          >
            <ModalHeader
              toggle={() => {
                setCreateVehiculeModal(false);
                resetVehiculeCreateEditForm();
                setVehiculeGrayCard(null);
              }}
            >
              Ajouter un véhicule
            </ModalHeader>
            <form onSubmit={createVehiculeHandleSubmit(createVehicule)}>
              <ModalBody>
                <div className="content-form-block">
                  <FormGroup className="form-icon icon-start">
                    <Label for="name">Véhicule</Label>
                    <Input
                      id="name"
                      innerRef={nameRef}
                      {...name}
                      placeholder="Nom"
                      type="text"
                      className="form-primary"
                    />
                    <span className="icon icon-primary icon-primary-fill">
                      {/* <img src={vehiculeIconGreen} alt="icon" /> */}
                      {<ReactSVG src={CalenderIconBlue} />}
                    </span>
                  </FormGroup>

                  <FormGroup className="form-icon icon-start">
                    <Label for="prenom">Type de véhicule</Label>
                    <Controller
                      name="type"
                      control={createVehiculeControl}
                      render={({field}) => (
                        <Select
                          options={VEHICULE_TYPES}
                          {...field}
                          closeMenuOnSelect={true}
                          classNamePrefix="select"
                          className="custom-select form-primary"
                          name="cabinetComps"
                        />
                      )}
                    />
                  </FormGroup>
                  {_type?.value !== "moped" && (
                    <FormGroup className="form-icon icon-start">
                      <Label for="prenom">
                        Puissance{" "}
                        <CustomTooltip
                          message={
                            "Vous trouverez cette information en case P.6 de votre carte grise"
                          }
                          id={`TooltipHP`}
                        >
                          <RiInformationLine id="TooltipHP" />
                        </CustomTooltip>
                      </Label>

                      <Controller
                        name="hp"
                        control={createVehiculeControl}
                        render={({field}) => (
                          <Select
                            options={HORSE_POWERS}
                            {...field}
                            closeMenuOnSelect={true}
                            classNamePrefix="select"
                            className="custom-select form-primary"
                            name="cabinetComps"
                          />
                        )}
                      />
                    </FormGroup>
                  )}

                  <FormGroup>
                    <Label for="proofOfIdentity">Carte grise</Label>
                    <FilePicker
                      className="form-icon icon-start form-file file-primary"
                      onChange={handleGrayCardCreationCahange}
                      state={vehiculeGrayCard}
                      fileReadyDelete={async () => {
                        try {
                          setCrudLoading(true);
                          await deleteFile(
                            "Vehicule",
                            singleVehicule?.id!,
                            "gray_card",
                            creds.token,
                            api
                          );
                          setVehiculeGrayCard(null);
                          await getVehicules();
                          setCrudLoading(false);
                          toast.dismiss();
                          toast.success(
                            "Votre ajout a été effectué avec succès!",
                            {
                              position: "bottom-right",
                              autoClose: 2000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            }
                          );
                        } catch (error: any) {
                          console.table("deleting file : ", error);
                        }
                      }}
                      fileStagedDelete={() => setVehiculeGrayCard(null)}
                      setUrl={setUrl}
                      setViewModal={setViewModal}
                    />
                  </FormGroup>
                  {errorMessage?.type === "not_valid" && (
                    <Alert color="danger">{errorMessage?.message}</Alert>
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    setCreateVehiculeModal(false);
                    resetVehiculeCreateEditForm();
                    setVehiculeGrayCard(null);
                  }}
                >
                  Annuler
                </Button>
                <Button color="primary" type="submit" loading={crudLoading}>
                  {crudLoading ? (
                    <Spinner color="light" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  ) : (
                    "Ajouter"
                  )}
                </Button>
              </ModalFooter>
            </form>
          </Modal>

          {/*edit vehicule modal*/}
          <Modal
            className="modal-primary modal-dialog-centered"
            isOpen={editVehiculeModal}
            toggle={() => {
              setEditVehiculeModal(false);
              resetVehiculeCreateEditForm();
              setSingleVehicule(null);
              setVehiculeGrayCard(null);
            }}
          >
            <ModalHeader
              toggle={() => {
                setEditVehiculeModal(false);
                resetVehiculeCreateEditForm();
                setSingleVehicule(null);
                setVehiculeGrayCard(null);
              }}
            >
              Editer une véhicule
            </ModalHeader>
            <form onSubmit={editVehiculeHandleSubmit(editVehicule)}>
              <ModalBody>
                <div className="content-form-block">
                  <FormGroup className="form-icon icon-start">
                    <Label for="name">Véhicule</Label>
                    <Input
                      id="name"
                      innerRef={edit_nameRef}
                      {...edit_name}
                      placeholder="Nom"
                      type="text"
                      className="form-primary"
                    />
                    <span className="icon icon-primary icon-primary-fill">
                      {/* <img src={vehiculeIconGreen} alt="icon" /> */}
                      {<ReactSVG src={vehiculeIconGreen} />}
                    </span>
                  </FormGroup>

                  <FormGroup className="form-icon icon-start">
                    <Label for="prenom">Type de véhicule</Label>
                    <Controller
                      name="edit_type"
                      control={editVehiculeControl}
                      render={({field}) => (
                        <Select
                          options={VEHICULE_TYPES}
                          {...field}
                          closeMenuOnSelect={true}
                          classNamePrefix="select"
                          className="custom-select form-primary"
                          name="cabinetComps"
                          isDisabled={true}
                        />
                      )}
                    />
                  </FormGroup>
                  {_edit_type?.value !== "moped" && (
                    <FormGroup className="form-icon icon-start">
                      <Label for="prenom">Puissance</Label>
                      <Controller
                        name="edit_hp"
                        control={editVehiculeControl}
                        render={({field}) => (
                          <Select
                            options={HORSE_POWERS}
                            {...field}
                            closeMenuOnSelect={true}
                            classNamePrefix="select"
                            className="custom-select form-primary"
                            name="cabinetComps"
                          />
                        )}
                      />
                    </FormGroup>
                  )}

                  <FormGroup>
                    <Label for="proofOfIdentity">Carte grise</Label>
                    <FilePicker
                      className="form-icon icon-start form-file file-primary"
                      onChange={handleGrayCardEditCahange}
                      state={vehiculeGrayCard}
                      fileReadyDelete={async () => {
                        try {
                          setCrudLoading(true);
                          await deleteFile(
                            "Vehicule",
                            singleVehicule?.id!,
                            "gray_card",
                            creds.token,
                            api
                          );
                          setVehiculeGrayCard(null);
                          await getVehicules();
                          setCrudLoading(false);
                          toast.dismiss();
                          toast.success(
                            "Votre ajout a été effectué avec succès!",
                            {
                              position: "bottom-right",
                              autoClose: 2000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            }
                          );
                        } catch (error: any) {
                          console.table("deleting file : ", error);
                        }
                      }}
                      fileStagedDelete={() => setVehiculeGrayCard(null)}
                      setUrl={setUrl}
                      setViewModal={setViewModal}
                    />
                  </FormGroup>
                  {errorMessage?.type === "not_valid" && (
                    <Alert color="danger">{errorMessage?.message}</Alert>
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    setEditVehiculeModal(false);
                    resetVehiculeCreateEditForm();
                    setSingleVehicule(null);
                    setVehiculeGrayCard(null);
                  }}
                >
                  Annuler
                </Button>
                <Button color="primary" type="submit" disabled={crudLoading}>
                  {crudLoading ? (
                    <Spinner color="light" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  ) : (
                    "Modifier"
                  )}
                </Button>
              </ModalFooter>
            </form>
          </Modal>

          {/*delete asset modal*/}
          <Modal
            className="modal-danger modal-dialog-centered"
            isOpen={deleteAssetModal.status}
            toggle={() => {
              setDeleteAssetModal({status: false, type: ""});
              setSingleIndemnity(null);
              setSingleVehicule(null);
            }}
          >
            <ModalHeader
              toggle={() => {
                setDeleteAssetModal({status: false, type: ""});
                setSingleIndemnity(null);
                setSingleVehicule(null);
              }}
            >
              Supprimer une{" "}
              {deleteAssetModal.type === "Vehicule"
                ? "véhicule"
                : "indemnité kilométrique"}
            </ModalHeader>

            <ModalBody>
              <div className="content-text p-lg-5">
                <p className="msg-text">
                  Vous êtes sur de vouloir supprimer{" "}
                  {deleteAssetModal.type === "Vehicule"
                    ? "la véhicule"
                    : "l'indemnité kilométrique"}{" "}
                  {singleIndemnity && singleIndemnity.id}
                  {singleVehicule && singleVehicule.name}?
                </p>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                outline
                onClick={() => {
                  setDeleteAssetModal({status: false, type: ""});
                  setSingleIndemnity(null);
                  setSingleVehicule(null);
                }}
              >
                Non
              </Button>
              <Button
                color="danger"
                onClick={async () =>
                  await deleteAsset(
                    singleIndemnity! || singleVehicule!,
                    deleteAssetModal.type === "Vehicule"
                      ? "Vehicule"
                      : "Indemnity"
                  )
                }
                disabled={crudLoading}
              >
                {crudLoading ? (
                  <Spinner color="light" type="border" size={"sm"}>
                    Loading...
                  </Spinner>
                ) : (
                  "Oui"
                )}
              </Button>
            </ModalFooter>
          </Modal>

          {/*create indemnity modal*/}
          <Modal
            className="modal-primary modal-dialog-centered modal-lg"
            isOpen={addIndemnityeModal}
            toggle={() => {
              setCreateIndemnityModal(false);
              resetIndemnityCreateEditForm();
              setErrorMessage(null);
            }}
          >
            <ModalHeader
              toggle={() => {
                setCreateIndemnityModal(false);
                resetIndemnityCreateEditForm();
                setErrorMessage(null);
              }}
            >
              Ajouter un indemnité kilométrique
            </ModalHeader>
            <form onSubmit={createIndemnityHandleSubmit(createIndemnity)}>
              <ModalBody>
                <div className="content-form-block">
                  <Row>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="prenom">Type de véhicule</Label>
                        <Controller
                          name="vehiculeType"
                          control={createIndemnitycontrol}
                          render={({field}) => (
                            <Select
                              options={VEHICULE_TYPES}
                              {...field}
                              closeMenuOnSelect={true}
                              classNamePrefix="select"
                              className="custom-select form-primary"
                              name="cabinetComps"
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="name">Véhicule</Label>
                        <Controller
                          name="vehicule"
                          control={createIndemnitycontrol}
                          render={({field}) => (
                            <Select
                              options={vehicules
                                .filter(
                                  (elt) => elt.type === _vehiculeType?.value
                                )
                                ?.reduce(
                                  (acc, curr) => [
                                    ...acc,
                                    {
                                      label: curr.name as string,
                                      value: curr.id as string,
                                    },
                                  ],
                                  [] as {label: string; value: string}[]
                                )}
                              {...field}
                              closeMenuOnSelect={true}
                              classNamePrefix="select"
                              className="custom-select form-primary"
                              name="cabinetComps"
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-end">
                        <Label for="mois">Mois</Label>
                        <Controller
                          control={createIndemnitycontrol}
                          name="month"
                          render={({field}) => (
                            <DatePicker
                              placeholderText="Mois"
                              onChange={(date: any) => field.onChange(date)}
                              selected={
                                field.value ? new Date(field.value) : null
                              }
                              className="form-control form-primary"
                              locale="fr"
                              dateFormat="MM/yyyy"
                              showMonthYearPicker
                            />
                          )}
                        />
                        {/* <Input
                          id="mois"
                          placeholder="mois"
                          type="month"
                          className="form-primary"
                          innerRef={monthRef}
                          {...month}
                        /> */}
                        <span className="icon icon-primary">
                          <RiCalendar2Line />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="prenom">Distance</Label>
                        <Input
                          id="mois"
                          innerRef={distanceRef}
                          {...distance}
                          type="number"
                          onWheel={(e) => (e.target as any).blur()}
                          className="form-primary"
                          step="0.01"
                          min={0}
                        />
                        <span className="icon icon-primary">
                          <RiPinDistanceFill />
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                  {errorMessage?.type === "not_valid" && (
                    <Alert color="danger">{errorMessage?.message}</Alert>
                  )}
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    setCreateIndemnityModal(false);
                    resetIndemnityCreateEditForm();
                    setErrorMessage(null);
                  }}
                >
                  Annuler
                </Button>
                <Button color="primary" type="submit" disabled={crudLoading}>
                  {crudLoading ? (
                    <Spinner color="light" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  ) : (
                    "Ajouter"
                  )}
                </Button>
              </ModalFooter>
            </form>
          </Modal>

          {/*edit indemnity modal*/}
          <Modal
            className="modal-primary modal-dialog-centered"
            isOpen={editIndemnityModal}
            toggle={() => {
              setEditIndemnityModal(false);
              resetIndemnityCreateEditForm();
            }}
          >
            <ModalHeader
              toggle={() => {
                setEditIndemnityModal(false);
                resetIndemnityCreateEditForm();
              }}
            >
              Editer une indemnité kilométrique
            </ModalHeader>
            <form onSubmit={editIndemnityHandleSubmit(editIndemnity)}>
              <ModalBody>
                <div className="content-form-block">
                  <Row>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="prenom">Type de véhicule</Label>
                        <Controller
                          name="edit_vehiculeType"
                          control={editIndemnityControl}
                          render={({field}) => (
                            <Select
                              options={VEHICULE_TYPES}
                              {...field}
                              closeMenuOnSelect={true}
                              classNamePrefix="select"
                              className="custom-select form-primary"
                              name="cabinetComps"
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="name">Véhicule</Label>
                        <Controller
                          name="edit_vehicule"
                          control={editIndemnityControl}
                          render={({field}) => (
                            <Select
                              options={vehicules
                                .filter(
                                  (elt) =>
                                    elt.type === _edit_vehiculeType?.value
                                )
                                ?.reduce(
                                  (acc, curr) => [
                                    ...acc,
                                    {
                                      label: curr.name as string,
                                      value: curr.id as string,
                                    },
                                  ],
                                  [] as {label: string; value: string}[]
                                )}
                              {...field}
                              closeMenuOnSelect={true}
                              classNamePrefix="select"
                              className="custom-select form-primary"
                              name="cabinetComps"
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-end">
                        <Label for="mois">Mois</Label>
                        <Controller
                          control={editIndemnityControl}
                          name="edit_month"
                          render={({field}) => (
                            <DatePicker
                              placeholderText="Mois"
                              onChange={(date: any) => field.onChange(date)}
                              selected={
                                field.value ? new Date(field.value) : null
                              }
                              className="form-control form-primary"
                              locale="fr"
                              dateFormat="MM/yyyy"
                              showMonthYearPicker
                            />
                          )}
                        />
                        {/* <Input
                          id="mois"
                          placeholder="mois"
                          type="month"
                          className="form-primary"
                          innerRef={edit_monthRef}
                          {...edit_month}
                        /> */}
                        <span className="icon icon-primary icon-primary-fill">
                          <RiCalendar2Line />
                        </span>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="prenom">Distance</Label>
                        <Input
                          id="mois"
                          innerRef={edit_distanceRef}
                          {...edit_distance}
                          type="number"
                          onWheel={(e) => (e.target as any).blur()}
                          className="form-primary"
                          step="0.01"
                          min={0}
                        />
                        <span className="icon icon-primary icon-primary-fill">
                          <RiPinDistanceFill />
                        </span>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  outline
                  onClick={() => {
                    setEditIndemnityModal(false);
                    resetIndemnityCreateEditForm();
                  }}
                >
                  Annuler
                </Button>
                <Button color="primary" type="submit" disabled={crudLoading}>
                  {crudLoading ? (
                    <Spinner color="light" type="border" size={"sm"}>
                      Loading...
                    </Spinner>
                  ) : (
                    "Ajouter"
                  )}
                </Button>
              </ModalFooter>
            </form>
          </Modal>
          <FileViewer
            url={url!}
            setUrl={setUrl}
            viewModal={viewModal}
            setViewModal={setViewModal}
          />
        </div>
      </div>
    </>
  );
};

export default Indemnity;
