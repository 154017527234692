import React, {ReactNode, useEffect, useRef, useState} from "react";
import "./MesTransactions.scss";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import {ISyncOrg, IBridgeAccount, IBridgeItem} from "../../interfaces";
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import ReviewIcon from "../../assets/ReviewIcon";
import Select from "react-select";
import {SiPypy} from "react-icons/si";
import {IoIosCash} from "react-icons/io";
import UserInfo from "../../components/user-info/UserInfo";
import {colors, data} from "../../constants";
import {
  RiAddFill,
  RiCalendar2Line,
  RiCloseLine,
  RiDownload2Fill,
  RiMoneyEuroCircleLine,
  RiUser3Line,
  RiWalletFill,
} from "react-icons/ri";
import BannerTop from "../../components/Banner/BannerTop";
import Tabs from "../../components/Tabs";
import TransactionsAvalider from "./TransactionsAvalider/TransactionsAvalider";
import TransactionsArchive from "./TransactionsArchive/TransactionsArchive";
import TopCard from "../../components/TopCard/TopCard";
import {useDispatch, useSelector} from "react-redux";
import {
  Client,
  Company,
  IFacture,
  ISubCategory,
  ITransaction,
  User,
} from "../../interfaces";
import axios from "axios";
import config from "../../config";
import {ErrorLogger} from "../../util/errorLogger";
import {Controller, SubmitHandler, useForm, useWatch} from "react-hook-form";
import moment from "moment";
import "moment/locale/fr";
import AttacheIcon from "../../assets/AttacheIcon";
import IconPlusYellow from "../../assets/images/svg/puls-icon-yellow.svg";
import MoneyIconBlue from "../../assets/images/svg/money-icon-blue.svg";
import MoneyIconGreen from "../../assets/images/svg/euro-icon-green.svg";
import packMoneyIcons from "../../assets/images/svg/pack-money.svg";
import MoneySacIconGreen from "../../assets/images/svg/money-sac-green.svg";
import CalenderIconBlue from "../../assets/images/svg/calender-icon-blue.svg";
import CalenderIconGreen from "../../assets/images/svg/calender-icon-green.svg";
import TrDec from "../../assets/images/icons/client/tr-dec.png";
import TrEnc from "../../assets/images/icons/client/tr-enc.png";
import {useTVA} from "../../util/hooks/useTVA";
import {fetchAllCompanies} from "../../store/reducers/companies-list/companiesSlice";
import {BootyPagination} from "../../components/table/pagination";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import useAxios from "../../util/hooks/useAxios";
import {
  AppPacks,
  BankSynced,
  CarbKeys,
  CarbVehTypes,
  InvoiceTransactionTypes,
  InvoiceTransactionParentTypes,
  PayDiffKeys,
  TransactionMediums,
  UserTypes,
  OptionType,
  BridgeItemStatus,
  NonImmoPacks,
} from "../../util/context";
import CrownIcon from "../../assets/CrownIcon";
import EMPTY_CONTENT from "../../assets/images/empty_content.svg";
import FirstSyncComp from "../../components/FirstSyncComp";
import DataTable from "react-data-table-component";
import {useFormatter} from "../../util/hooks/useFormatter";
import {BsBox, BsCheck2Circle} from "react-icons/bs";
import ViewIcon from "../../assets/ViewIcon";
import FileViewer from "../../components/FileViewer";
import FilePicker from "../../components/FilePicker";
import {toast, ToastContainer} from "react-toastify";
import {FaFileInvoice} from "react-icons/fa";
import ZoomIcon from "../../assets/ZoomIcon";
import CompteSynchronise, {
  RedirectUrls,
} from "../CompanyInfo/CompteSynchronise/CompteSynchronise";
import {ReactSVG} from "react-svg";
registerLocale("fr", fr);

export interface TransactionsProps {}
export type CheckItem = {
  id: string | number;
  status: string | number;
  status_code_info?: string | number | null;
  status_code_description?: string | number | null;
  bank_id?: string | number | null;
};
type TabsType = {
  label: string;
  index: number;
  Component: React.FC<{}>;
}[];

type GeneralFormValues = {
  amount: number | null;
  label: string | null;
  date: string | null;
  vat: string | null;
  iban_id: {value: string; label: string} | null;
  category: {value: string; label: string} | null;
  carbVehType: {value: string; label: string} | null;
  documents?: any;
  modalType?: string | null;
  relatedToFactureType?: string | null;
  sub_category_id?: string | null;
  facture_ids: string[] | null;
};

// Tabs Array
const tabs: TabsType = [
  {
    label: "À valider",
    index: 1,
    Component: TransactionsAvalider,
  },
  {
    label: "Archivées",
    index: 2,
    Component: TransactionsArchive,
  },
];

// Tabs Array
const entrepTabs: TabsType = [
  {
    label: "À Comptabiliser",
    index: 1,
    Component: TransactionsAvalider,
  },
  {
    label: "Archivées",
    index: 2,
    Component: TransactionsArchive,
  },
];

const MesTransactions = ({
  context,
  pack,
  client,
  tempState,
  setTempState,
}: {
  context?: string;
  pack?: string;
  client?: User;
  tempState?: any;
  setTempState?: any;
}) => {
  const [selectedTab, setSelectedTab] = useState<number>(tabs[0].index);
  const [currentDate, setCurrentDate] = useState<string>(
    moment().format("YYYY-MM-DD hh:mm")
  );
  const [addEspeceModal, setAddEspeceModal] = useState<boolean>(false);

  const [isImmobFire, setIsImmobFire] = useState<boolean>(false);
  const [isImmobSubCateg, setIsImmobSubCateg] = useState<ISubCategory | null>();
  const [isImmobTrans, setIsImmobTrans] = useState<ITransaction | null>();

  const [isPayDiffFire, setIsPayDiffFire] = useState<boolean>(false);
  const [isPayDiffSubCateg, setIsPayDiffSubCateg] =
    useState<ISubCategory | null>();
  const [isPayDiffTrans, setIsPayDiffTrans] = useState<ITransaction | null>();

  const [reloadTrs, setReloadTrs] = useState<boolean>(false);
  const [addNoteFraisModal, setAddNoteFraisModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [archiveAllErrorModal, setArchiveAllErrorModal] =
    useState<boolean>(false);

  const [archiveAllInvalidStatus, setArchiveAllInvalidStatus] =
    useState<boolean>(false);
  const [archiveAllInvalidDocs, setArchiveAllInvaliddocs] =
    useState<boolean>(false);
  const [archiveAllSepcialInvalidDocs, setArchiveAllSpecialInvaliddocs] =
    useState<boolean>(false);
  const [inputSubCategs, setInputSubCategs] = useState<ISubCategory[]>([]);
  const [outputSubCategs, setOutputSubCategs] = useState<ISubCategory[]>([]);
  // const [errorMessage, setErrorMessage] = useState<{
  //   type: string;
  //   message: string;
  // } | null>(null);

  type SyncBankFormValues = {
    [prop: string]: OptionType[];
  };

  const [billSuggestModal, setBillSuggestModal] = useState<boolean>(false);
  const [billAdd, setBillAdd] = useState<{
    id: string;
    isLoading: boolean;
  } | null>(null);
  const [billAddCustom, setBillAddCustom] = useState<boolean>(false);
  const [billSuggestions, setBillSuggestions] = useState<IFacture[]>([]);
  const [viewModal, setViewModal] = useState<boolean>(false);
  const [createLoading, setCreateLoading] = useState<boolean>(false);
  const [url, setUrl] = useState<string | null>(null);
  const [documents, setDocuments] = useState<any>([]);

  const [transactionNameSearch, setTransactionNameSearch] = useState("");

  const {companiesList} = useSelector(
    (state: {companiesList: Company[]}) => state.companiesList
  ) as unknown as {companiesList: Company[]};

  const {user, company} = useSelector(
    (state: {root: object; user: object; company: object}) => state.user
  ) as {user: User; company: Company};

  const [spendingData, setSpendingData] = useState<number>(0);
  const [itData, setItData] = useState<number>(0);
  const [syncModal, setSyncModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [singleSyncOrg, setSingleSyncOrg] = useState<ISyncOrg | null>();
  const [firstLoading, setFirstLoading] = useState<boolean>(false);
  const [choiceModal, setChoiceModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<{
    type: string;
    message: string | JSX.Element | ReactNode;
  } | null>(null);
  const {
    control: syncControl,
    setValue: syncSetValue,
    getValues: syncGetValue,
    watch: syncWatch,
    register: syncRegister,
    handleSubmit: syncHandleSubmit,
    reset: syncReset,
    formState: {errors: syncError},
  } = useForm<SyncBankFormValues>({});
  const [bridegAccounts, setBridgeAccounts] = useState<IBridgeAccount[]>([]);

  const [isQontoUser, setIsQontoUser] = useState<boolean>(false);

  const {id} = useParams();

  const creds = useSelector(
    (state: {root: object; user: object}) => state.root
  ) as {
    user_id: string;
    company_id: string;
    token: string;
    role: string;
    pack: string;
  };

  const credsApp = useSelector(
    (state: {root: object; user: object}) => state.root
  ) as {
    user_id: string;
    company_id: string;
    token: string;
    role: string;
    application_id: string;
  };

  const {newNotif} = useSelector(
    (state: {messagesNotifs: object}) => state.messagesNotifs
  ) as {newNotif: boolean};

  const navigate = useNavigate();
  let api = useAxios();
  const {setDecimalDigits} = useFormatter();
  const [totalBillRows, setTotalBillRows] = useState(0);
  const [currentBillPage, setCurrentBillPage] = useState(1);
  const handleBillPageChange = (page: number) => {
    setCurrentBillPage(page);
  };

  const billColumns = [
    {
      name: "Nº facture",
      selector: (row: any) => String(row.id).toUpperCase(),
      sortable: true,
    },
    {
      name: "Société",
      selector: (row: any) =>
        // i know it's stupid,don't judge...
        row.clientInfo &&
        row.clientInfo.clientName &&
        row.clientInfo.clientName !== ""
          ? row.clientInfo.clientName
          : "",
      sortable: true,
    },
    {
      name: "Total HT",
      selector: (row: any) => `${setDecimalDigits(row.totalHT)} €`,
      sortable: true,
    },
    {
      name: "Total TTC",
      selector: (row: any) => `${setDecimalDigits(row.totalTTC)} €`,
      sortable: true,
    },
    {
      name: "Date de création",
      selector: (row: any) => moment(row.createdAt).format("DD/MM/YYYY"),
      sortable: true,
    },
    {
      name: "Action",
      button: true,
      cell: (row: IFacture) => (
        <div className="table-action">
          {row.billS3link && row.billS3link.key && (
            <>
              <button
                className="btn btn-blue"
                onClick={() => {
                  setUrl(row.billS3link.url);
                  setViewModal(true);
                }}
              >
                <ViewIcon />
              </button>
              <button
                className="btn btn-green"
                onClick={() => {
                  const canAdd = checkCanAddInvoice(row, getValues("amount")!);
                  if (!canAdd) {
                    toast.dismiss();
                    toast.warning(
                      `Le montant de la facture choisie dépassera le montant restant de cette transaction.`,
                      {
                        position: "bottom-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );
                    return;
                  }
                  addBillToTransaction(row);
                }}
              >
                {billAdd && billAdd.id === row.id && billAdd.isLoading ? (
                  <Spinner color="success" type="border" size={"sm"}>
                    Loading...
                  </Spinner>
                ) : (
                  <BsCheck2Circle />
                )}
              </button>
            </>
          )}
        </div>
      ),
    },
  ];
  /** setting company's factures in state to be linked to transactionsnupon manual creation  */
  const checkTransactionsBills = async (company_id: string) => {
    try {
      let payload: any = {
        where: {
          company_id: company_id,
          relatedToFactureType: null,
        },
        perPage: 10,
        pageIndex: currentBillPage,
        order: [
          ["createdAt", "DESC"],
          ["id", "DESC"],
        ],
      };

      const {data} = await api.post(`/api/Facture/All`, payload, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      setBillSuggestions([...data.data]);
      setTotalBillRows(data.count);
    } catch (error: any) {
      ErrorLogger("deleting a transaction", error);
    }
  };

  /** adding a facture or invoice to a transaction by linking them both and setting the transaction's documents attribute to have the same
   * document pdf or ... as the the facture
   */
  const addBillToTransaction = async (facture: IFacture) => {
    try {
      setBillAdd({
        id: facture.id,
        isLoading: true,
      });

      if (!getValues("facture_ids") || getValues("facture_ids")?.length === 0) {
        setValue("facture_ids", [facture.id]);
      } else {
        setValue("facture_ids", [...getValues("facture_ids")!, facture.id]);
      }

      //getting the facture document blob to be set to the transaction
      const {data} = await api.get(facture.billS3link.url, {
        responseType: "blob",
      });

      const subCategs = [...inputSubCategs, ...outputSubCategs];

      if (data) {
        const file = new File([data], facture.billS3link.key, {
          type: data.type,
        });
        // if the facture is already assigned to 1 or multiple transactions
        if (facture.transactions && facture.transactions.length > 0) {
          const parentTransactionIndex = facture.transactions.findIndex(
            (elt) => elt.relatedToFactureType === "parent"
          );

          if (parentTransactionIndex > -1) {
            //parentTransaction is refrencing the facture
            // due to the fact that a facture can't have 1 or multiple catgories
            // so with each facture created / imported a transaction of relatedToFactureType == parent will be created and associated with it
            // to enable us to add tvas and categories
            const parentTransaction =
              facture.transactions[parentTransactionIndex];
            // setting the created transaction type to child
            // this means that the transaction created is a transaction created to pay off a facture / invoice
            setValue("relatedToFactureType", "child");
            setDocuments((prevState: any) => [...(prevState || []), file]);

            // this means that 1 facture will be payed by multiple transactions
            // NTrs + 1Fact
            if (
              getValues("amount") &&
              parentTransaction.amount > getValues("amount")!
            ) {
              if (parentTransaction.sub_category_id) {
                const subcategIndex = subCategs.findIndex(
                  (elt) => elt.id === parentTransaction.sub_category_id
                );
                if (subcategIndex > -1) {
                  // here is the facture has a category assigned to it,the created transaction will have the same category as the facture
                  setValue("category", {
                    value: parentTransaction.sub_category_id,
                    label: subCategs[subcategIndex].name,
                  });
                }
              }
            }
            // this means that 1 transaction will pay for multiple factures
            // 1Tr + NFacts
            if (
              getValues("amount") &&
              parentTransaction.amount <= getValues("amount")!
            ) {
              // the transaction created will have the sum of the associated factures's tva amounts
              setValue(
                "vat",
                facture.transactions.length === 1 &&
                  facture.transactions[0].relatedToFactureType === "parent"
                  ? facture.totalTVA
                  : `${
                      parseFloat(getValues("vat") || "0") +
                      parseFloat(facture.totalTVA)
                    }`
              );
            }
          }
        }
      }
      setBillAdd(null);
      toast.dismiss();
      toast.success(`Votre facture a été ajoutée avec succès.`, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error: any) {
      ErrorLogger("archiving a transaction", error);
      setBillAdd(null);
    }
  };

  // getting spending stats
  // the explanation will be provided in the backend
  const getDashboardSependingIT = async (foundComp: Company) => {
    try {
      const {data} = await api.post(
        `/api/stats/dashboardSependingIT`,
        {
          dateFrom: moment(currentDate).startOf("month").format("YYYY-MM-DD"),
          dateTo: moment(currentDate).endOf("month").format("YYYY-MM-DD"),
          companyId: foundComp?.id,
          pack: foundComp?.pack,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      const {spending, it} = data;

      setSpendingData(spending);
      setItData(it);
    } catch (error: any) {
      ErrorLogger("getting spending and it data in dashboard", error);
    }
  };

  const getDashboardSependingITWrapper = () => {
    if (user.id && user.role === UserTypes.Client && company && company?.pack) {
      getDashboardSependingIT(company!);
    }
  };

  // creating joins between the facture and transaction
  // the realtion is many to many so joins are required
  const linkTransactionstoBill = async (
    transactionId: string,
    factureIds: string[]
  ) => {
    try {
      for (let factureId of factureIds) {
        await api.post(
          `/api/Facture_Transaction/Create`,
          {
            TransactionId: transactionId,
            FactureId: factureId,
          },
          {
            headers: {
              "x-access-token": creds.token,
            },
          }
        );
      }

      toast.dismiss();
      toast.success(`Votre facture a été ajoutée avec succès.`, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error: any) {
      ErrorLogger("creating a Facture_Transaction", error);
    }
  };

  useEffect(() => {
    getDashboardSependingITWrapper();
    if (context) {
      let shouldCheckBills = true;
      if (
        creds.role === UserTypes.Admin &&
        companiesList &&
        companiesList.length > 0
      ) {
        let comp = companiesList.find((elt) => elt.id === context);
        shouldCheckBills = NonImmoPacks.includes(comp?.pack! as any);
      }
      if (creds.role === UserTypes.Client) {
        shouldCheckBills = NonImmoPacks.includes(company?.pack! as any);
      }
      // here we are removing the company's facture fetch for some specific packs
      if (shouldCheckBills) {
        checkTransactionsBills(context!);
      }
    }
  }, [context, user]);

  // getting sub categories to use them in the transaction creation
  const getSubCategories = async () => {
    try {
      setLoading(true);

      const {data: outputs} = await api.post(
        `/api/SubCategory/all`,
        {
          where: {
            key: {
              iLike: `%OUT-%`,
            },
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      const {data: inputs} = await api.post(
        `/api/SubCategory/all`,
        {
          where: {
            key: {
              iLike: `%IN-%`,
            },
          },
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      if (inputs.data && outputs.data.length > 0) {
        if (outputs.data.length > 0) {
          setOutputSubCategs(outputs.data);
        }
        if (inputs.data.length > 0) {
          setInputSubCategs(inputs.data);
        }
      }
      setLoading(false);
    } catch (error: any) {
      ErrorLogger("getting sub categories", error);
    }
  };

  useEffect(() => {
    getSubCategories();
  }, []);

  useEffect(() => {
    getDashboardSependingITWrapper();
  }, [currentDate]);

  useEffect(() => {
    if (context) {
      checkTransactionsBills(context!);
    }
  }, [currentBillPage]);

  const {
    watch,
    control,
    register,
    handleSubmit,
    reset,
    setValue,
    formState: {errors},
    getValues,
  } = useForm<GeneralFormValues>({});

  const {category: _category} = watch();

  const {setTVA} = useTVA();

  // creating a transaction manually
  const createTransaction: SubmitHandler<GeneralFormValues> = async (
    form: GeneralFormValues
  ) => {
    try {
      setCreateLoading(true);
      if (!form.label || !form.amount || !form.date) {
        setCreateLoading(false);
        setErrorMessage({
          type: "invalid_form",
          message: "Veuillez fournir toutes les données de la transaction",
        });
        return;
      }

      const formData = new FormData();
      let subCateg = {} as Partial<ISubCategory>;

      formData.append("label", form.label as string);
      formData.append("status", 100 as any);
      formData.append("companyId", context as string);
      formData.append("date", moment(form.date).format("YYYY-MM-DD") as string);
      // with what the transaction is created
      formData.append("medium", TransactionMediums.Manual);

      const subCategs = [...inputSubCategs, ...outputSubCategs];

      if (form.category) {
        const categIndex = subCategs.findIndex(
          (elt) => elt.id === form.category?.value
        );
        if (categIndex > -1) {
          formData.append("sub_category_id", form.category?.value as string);
          formData.append("category", subCategs[categIndex].key as string);
        }
      }

      if (form.modalType === "positive") {
        subCateg = inputSubCategs.find(
          (elt) => elt.id === form.category?.value
        )!;
        formData.append("amount", form.amount as any);
        formData.append("type", "cash");
      } else {
        subCateg = outputSubCategs.find(
          (elt) => elt.id === form.category?.value
        )!;
        formData.append("amount", -form.amount as any);
        formData.append("type", "expense");
      }

      // if the transaction is associated to carburant category,we should specify the type of the vehicule to use it in the export
      if (form.carbVehType && form.carbVehType.value) {
        formData.append("carbVehType", form.carbVehType.value);
      }

      // if the trasnaction is associated to a facture and it is paying for 1 or multiple factures
      if (form.relatedToFactureType === "child") {
        formData.append("relatedToFactureType", "child");
      }

      // if the vat is already assigned
      if (form.vat) {
        formData.append("vat", form.vat);
      } else {
        if (
          subCateg?.TVA &&
          !isNaN(parseFloat(subCateg?.TVA)) &&
          parseFloat(subCateg?.TVA) !== 0
        ) {
          const tva = setTVA(
            form.modalType === "positive" ? form?.amount! : -form?.amount!,
            parseFloat(subCateg?.TVA) / 100
          );

          formData.append(
            "vat",
            String(
              form.carbVehType &&
                form.carbVehType.value &&
                form.carbVehType.value === CarbVehTypes.Tourisme
                ? tva * 0.8
                : tva
            )
          );
        } else {
          formData.append("vat", "0");
        }
      }

      if (documents && documents.length > 0) {
        for (let index = 0; index < documents.length; index++) {
          const element = documents[index];
          formData.append("documents", element, element.name);
        }
      }

      const {data} = await api.post(`/api/Transaction/Create`, formData, {
        headers: {
          "x-access-token": creds.token,
        },
      });

      // here we check if the trasnaction is linked to 1 or multiple facture,wo we can create the joins
      if (
        form.relatedToFactureType === "child" &&
        data.id &&
        form.facture_ids!.length > 0
      ) {
        await linkTransactionstoBill(data.id, form.facture_ids!);
      }

      setCreateLoading(false);
      setReloadTrs(true);
      resteForm();
      setAddEspeceModal(false);
      setAddNoteFraisModal(false);
      await checkTransactionsBills(context!);

      // if the transaction is created with pay diff / enc diff category
      // if yes it will fire the pay diff / enc diff modal to create the associated pay diff trasnaction
      if (
        (Object.values(PayDiffKeys) as unknown as string[]).includes(
          subCateg.key!
        )
      ) {
        setIsPayDiffTrans(data);
        setIsPayDiffSubCateg(subCateg as ISubCategory);
        setIsPayDiffFire(true);
        return;
      }

      // if the transaction is an immobilisation trasnaction
      // and the the rule aatribuet is true (it is set in the sub-categories module)
      // it will fire the immob modal
      if (
        subCateg.amount &&
        subCateg.rule &&
        Math.abs(parseFloat(String(form.amount))) >=
          Math.abs(parseFloat(subCateg.amount)) &&
        String(subCateg.rule) === "true" &&
        subCateg.redirectCategory_id
      ) {
        setIsImmobTrans(data);
        setIsImmobSubCateg(subCateg as ISubCategory);
        setIsImmobFire(true);
        return;
      }
    } catch (error: any) {
      ErrorLogger("transaction creation form", error);
      setCreateLoading(false);
      setErrorMessage({
        type: "undexpected_issue",
        message:
          "Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.",
      });
    }
  };

  // this will archive all the transactions
  // and their associated pay diff / enc diff transactions
  const archiveAllTransactions = async (company_id: string, force = 0) => {
    try {
      setLoading(true);
      setArchiveAllInvalidStatus(false);
      setArchiveAllInvaliddocs(false);
      setArchiveAllSpecialInvaliddocs(false);
      setErrorMessage(null);
      let payload: {company_id: string; force?: number} = {
        company_id,
      };
      if (force > 0) {
        payload.force = force;
      }

      const {data} = await api.post(`/api/transaction/archiveAll`, payload, {
        headers: {
          "x-access-token": creds.token,
        },
      });
      if (!data.acrhived) {
        setArchiveAllErrorModal(true);
        if (!data.isValid) setArchiveAllInvalidStatus(true);
        if (
          !data.hasDocuments.status &&
          data.hasDocuments.check === "normal" &&
          data.isValid
        )
          setArchiveAllInvaliddocs(true);
        if (
          !data.hasDocuments.status &&
          data.hasDocuments.check === "special" &&
          data.isValid
        )
          setArchiveAllSpecialInvaliddocs(true);
        setLoading(false);
        return;
      }
      setLoading(false);
      setReloadTrs(true);
      setArchiveAllErrorModal(false);
      setArchiveAllInvalidStatus(false);
      setArchiveAllInvaliddocs(false);
      setArchiveAllSpecialInvaliddocs(false);
    } catch (error: any) {
      ErrorLogger("archive all transactions", error);
      setLoading(false);
      setErrorMessage({
        type: "undexpected_issue",
        message:
          "Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.",
      });
    }
  };

  const resteForm = () => {
    reset({
      label: null,
      amount: null,
      date: null,
      category: null,
      modalType: null,
      facture_ids: [],
    });
    setErrorMessage(null);
    setDocuments([]);
  };

  const {ref: labelRef, ...label} = register("label");
  const {ref: amountRef, ...amount} = register("amount");
  const {ref: dateRef, ...date} = register("date");

  const handleDocumentsChange = (event: {target: {files: any}}) => {
    setDocuments((prevState: any) => [
      ...(prevState || []),
      ...(event.target.files as any),
    ]);
  };

  // this checks if the transaction's amount can handle adding another facture to be payed or not
  const checkCanAddInvoice = (invoice: IFacture, amount: any) => {
    const alreadyAddedDocs = documents
      .map((elt: File) => elt.name)
      .map((key: string) => {
        const inBillSuggIndex = billSuggestions.findIndex(
          (elt) => elt.billS3link.key === key
        );
        if (inBillSuggIndex > -1) {
          return billSuggestions[inBillSuggIndex];
        } else {
          return;
        }
      });

    if (alreadyAddedDocs.length === 0) {
      return true;
    }

    return (
      Math.abs(parseFloat(amount)) >=
      [...alreadyAddedDocs, invoice].reduce(
        (acc, curr) => acc + Math.abs(parseFloat(curr.totalTTC)),
        0 as number
      )
    );
  };

  const firstSync = async () => {
    try {
      setFirstLoading(true);
      setErrorMessage(null);
      const {data} = await api.post(
        `/api/accountSync/createRedirectURL`,
        {
          companyId: creds.role === UserTypes.Client ? creds.company_id : id,
          syncType: "new",
          from: credsApp.application_id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );
      if (data.error) {
        setFirstLoading(false);
        setErrorMessage({
          type: "first_sync_error",
          message: (
            <p>
              Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.
            </p>
          ),
        });
        return;
      }
      window.location = data.url;
      setFirstLoading(false);
      setSyncModal(false);
      console.log(client?.id, "client iid");
    } catch (error: any) {
      ErrorLogger("creating redirect url", error);
      setFirstLoading(false);
      setErrorMessage({
        type: "first_sync_error",
        message: (
          <p>
            Oups ! Quelque chose a mal tourné, veuillez réessayer plus tard.
          </p>
        ),
      });
    }
  };

  const billSuggestionsChecker = (type: string) =>
    billSuggestions.filter((elt) => {
      let transactions = elt.transactions;
      if (transactions && transactions.length > 0) {
        let parentIndex = transactions.findIndex(
          (tr) => tr.relatedToFactureType === InvoiceTransactionTypes.Parent
        );

        if (parentIndex > -1) {
          if (type === "enc") {
            if (
              transactions[parentIndex].parentType ===
                InvoiceTransactionParentTypes.Enc ||
              transactions[parentIndex].parentType ===
                InvoiceTransactionParentTypes.ManualEnc
            ) {
              return elt;
            } else {
              return;
            }
          }
          if (type === "dec") {
            if (
              transactions[parentIndex].parentType ===
              InvoiceTransactionParentTypes.Dec
            ) {
              return elt;
            } else {
              return;
            }
          }
        } else {
          return elt;
        }
      }
    });
  // dep
  const deleteSyncOrg = async () => {
    try {
      setLoading(true);
      await api.post(
        `/api/SyncOrganization/Delete`,
        {
          id: singleSyncOrg?.id,
        },
        {
          headers: {
            "x-access-token": creds.token,
          },
        }
      );

      // await getSyncorgs();
      setDeleteModal(false);
      setSingleSyncOrg(null);
      setLoading(false);
    } catch (error: any) {
      ErrorLogger("updating company form", error);
    }
  };

  // this sets the searched transaction name in state
  const handleTransactionsSearch = (value: string) => {
    if (transactionNameSearch !== "" && value === "") {
      setReloadTrs(true);
    }
    setTransactionNameSearch(value);
  };

  // const checkIfQontoUser = async () => {
  //   try {
  //     const { data: qontoAccounts } = await api.post(
  //       `/api/BridgeItem/All`,
  //       {
  //         where: {
  //           company_id: creds.role !== UserTypes.Client ? id : creds.company_id,
  //         },
  //       },
  //       {
  //         headers: {
  //           "x-access-token": creds.token,
  //         },
  //       }
  //     );

  //     const items = qontoAccounts.data as IBridgeItem[];

  //     if (
  //       items.length > 0 &&
  //       items.find((elt: { bankId: number }) => elt.bankId === 421)
  //     ) {
  //       setIsQontoUser(true);
  //     }

  //     setBridgeAccounts(
  //       items
  //         .reduce(
  //           (acc, curr) => [...acc, ...curr.bridge_accounts],
  //           [] as IBridgeAccount[]
  //         )
  //         .filter((elt) => elt.iban)
  //     );
  //   } catch (error: any) {
  //     ErrorLogger("getting indemnities", error);
  //   }
  // };

  // useEffect(() => {
  //   checkIfQontoUser();
  // }, []);

  console.log("company.bankSynced", company.bankSynced);

  return (
    <div className="page">
      {user.id && user.role === UserTypes.Client ? (
        <>
          <div
            className={`top-content ${
              creds.role === UserTypes.Client ? "client-resize-header" : ""
            }`}
          >
            <Row>
              <Col lg={8} md={12} className="blockBanner">
                <BannerTop banner={data.transactions} />
              </Col>
              <Col lg={4} md={12}>
                <Card className="blockUser">
                  <Button
                    className="btn-icon btn-icon-primary"
                    color="light"
                    onClick={() => navigate("/tickets")}
                  >
                    {newNotif && <Badge color="primary"> </Badge>}
                    <CrownIcon stroke={colors.primaryColor} />
                  </Button>
                  <UserInfo user={user} />
                  <Button
                    className="btn-icon btn-icon-primary"
                    color="light"
                    onClick={() =>
                      navigate({
                        pathname: "/profil/client",
                        // search: "?page=1&tab=1",
                      })
                    }
                  >
                    <RiUser3Line />
                  </Button>
                </Card>
              </Col>
            </Row>
          </div>
          {creds.role === UserTypes.Client &&
            company.bankSynced !== BankSynced.YES && (
              <FirstSyncComp
                id={(creds.role === UserTypes.Client ? creds.company_id : id)!}
              />
            )}
          <div className="action-top-odt">
            <span className="month-label">
              {moment(currentDate).format("MMMM/YYYY")}
            </span>
            <div className="od-tva-wrapper">
              <button
                type="button"
                className="btn btn-outline-primary "
                onClick={() =>
                  setCurrentDate(
                    moment(currentDate)
                      .subtract(1, "month")
                      .format("YYYY-MM-DD hh:mm")
                  )
                }
              >
                Mois précédent
              </button>
              <button
                type="button"
                className="btn btn-primary "
                onClick={() =>
                  setCurrentDate(
                    moment(currentDate)
                      .add(1, "month")
                      .format("YYYY-MM-DD hh:mm")
                  )
                }
              >
                Mois suivant
              </button>
            </div>
          </div>
          <div className="Center-content">
            <Row>
              <Col lg="4" md="12" className="client-encais">
                <TopCard
                  title="Encaissement"
                  text={`${Math.abs(itData)} €`}
                  icon={TrEnc}
                  className="card-primary"
                />
              </Col>
              <Col lg="4" md="12" className="client-estims">
                <TopCard
                  title={
                    company?.pack === AppPacks.Entrepreneur
                      ? "Imposition Estimée"
                      : "Décaissement"
                  }
                  text={`${Math.abs(spendingData)} €`}
                  icon={TrDec}
                  className="card-warning"
                />
              </Col>
              <Col lg="4" md="12" className="client-add-tr-wrapper">
                <Card
                  className="client-add-tr plus-add"
                  onClick={() => setAddEspeceModal(true)}
                >
                  Ajouter des espèces
                </Card>
                <Card
                  className="client-add-tr neg-add"
                  onClick={() => setAddNoteFraisModal(true)}
                >
                  Ajouter une note de frais
                </Card>
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <></>
      )}

      <div>
        <Card className="card-Table table-primary">
          <div
            className={`transactions-action-top-tabs action-top-tabs ${
              user.role === UserTypes.Client ? "client-style" : "admin-style"
            }`}
          >
            {user.role !== UserTypes.Client && (
              <>
                <Button
                  color="secondary"
                  onClick={() => setAddEspeceModal(true)}
                  type="button"
                >
                  <img
                    src={IconPlusYellow}
                    alt="icon"
                    className="svg-warning"
                  />
                  Ajouter des espèces
                </Button>
                <Button
                  color="primary"
                  onClick={() => setAddNoteFraisModal(true)}
                  type="button"
                >
                  <img
                    src={IconPlusYellow}
                    alt="icon"
                    className="svg-warning"
                  />
                  Ajouter une note de frais
                </Button>
              </>
            )}

            <div className="form-icon icon-end transaction-search-bar">
              <Input
                id="mc"
                placeholder="Chercher une transaction"
                type="text"
                className="form-secondary"
                onChange={(e) => handleTransactionsSearch(e.target.value)}
                value={transactionNameSearch}
              />
              <button
                type="submit"
                className="icon icon-secondary "
                onClick={() => handleTransactionsSearch("")}
              >
                {transactionNameSearch !== "" ? <RiCloseLine /> : <ZoomIcon />}
              </button>
            </div>
            {selectedTab === 1 && pack !== AppPacks.Entrepreneur && (
              <Button
                color="warning"
                outline
                type="button"
                onClick={async () => await archiveAllTransactions(context!)}
              >
                Archiver tout
              </Button>
            )}
            {creds.role === UserTypes.Client && (
              <Button
                color="secondary"
                onClick={() => {
                  // setSyncModal(true);
                  if (creds.role === UserTypes.Client) {
                    navigate("/profil/entreprise/sync", {replace: true});
                    return;
                  }
                  setTempState({
                    parent: 3,
                    child: 5,
                  });
                }}
              >
                {/* <ReviewIcon /> */}
                Synchroniser
              </Button>
            )}
          </div>
          <div className="openbtn text-center">
            {/* delete sync org */}
            <Modal
              className="modal-danger modal-dialog-centered"
              isOpen={deleteModal}
              toggle={() => {
                setSingleSyncOrg(null);
                setDeleteModal(false);
              }}
            >
              <ModalHeader
                toggle={() => {
                  setSingleSyncOrg(null);
                  setDeleteModal(false);
                }}
              >
                Supprimer une organisation
              </ModalHeader>
              <ModalBody>
                <div className="content-text p-lg-5">
                  <p className="msg-text">
                    Vous êtes sur de vouloir supprimer cette organisation{" "}
                    {singleSyncOrg?.name} ?
                  </p>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  outline
                  type="button"
                  onClick={() => {
                    setSingleSyncOrg(null);
                    setDeleteModal(false);
                  }}
                >
                  Non
                </Button>
                <Button
                  color="danger"
                  type="submit"
                  onClick={async () => await deleteSyncOrg()}
                >
                  Oui
                </Button>
              </ModalFooter>
            </Modal>

            {/* select sync type */}
            <Modal
              className="modal-secondary modal-dialog-centered"
              isOpen={syncModal}
              toggle={() => {
                setSyncModal(false);
                setErrorMessage(null);
                setFirstLoading(false);
              }}
            >
              <ModalHeader
                toggle={() => {
                  setSyncModal(false);
                  setErrorMessage(null);
                  setFirstLoading(false);
                }}
              >
                Confirmer la synchronisation
              </ModalHeader>
              <ModalBody>
                <div className="sync-modal">
                  <div className="action-button-wrapper">
                    <Button
                      color="secondary"
                      type="submit"
                      onClick={() => {
                        setFirstLoading(false);
                        setErrorMessage(null);
                        setChoiceModal(true);
                      }}
                    >
                      Comptes bancaires déjà synchronisées
                    </Button>
                  </div>
                  <div className="action-button-wrapper">
                    <Button
                      color="secondary"
                      outline
                      type="submit"
                      disabled={firstLoading}
                      onClick={async () => {
                        await firstSync();
                      }}
                    >
                      Nouveau Compte bancaire
                    </Button>
                  </div>
                </div>
                {firstLoading && (
                  <div className="d-flex justify-content-center text-info mt-2">
                    Création d'un lien de synchronisation, veuillez patienter.
                  </div>
                )}
                {errorMessage?.type === "first_sync_error" && (
                  <div className="d-flex justify-content-center text-danger mt-2">
                    {errorMessage?.message}
                  </div>
                )}
              </ModalBody>
            </Modal>
          </div>
          <Tabs
            selectedTab={selectedTab}
            onClick={setSelectedTab}
            tabs={pack === AppPacks.Entrepreneur ? entrepTabs : tabs}
            context={context}
            callback={setReloadTrs}
            reload={reloadTrs}
            company={
              companiesList && companiesList.length > 0
                ? companiesList.find((elt) => elt.id === context)
                : company
            }
            immobStateHandler={{
              isImmobFire,
              setIsImmobFire,
              isImmobSubCateg: isImmobSubCateg || null,
              setIsImmobSubCateg,
              isImmobTrans: isImmobTrans || null,
              setIsImmobTrans,
            }}
            payDiffStateHandler={{
              isPayDiffFire,
              setIsPayDiffFire,
              isPayDiffSubCateg: isPayDiffSubCateg || null,
              setIsPayDiffSubCateg,
              isPayDiffTrans: isPayDiffTrans || null,
              setIsPayDiffTrans,
            }}
            transactionNameSearch={transactionNameSearch}
            setTransactionNameSearch={setTransactionNameSearch}
          />
        </Card>
      </div>
      <div className="openbtn text-center">
        {/*add espece*/}
        <Modal
          className="modal-secondary modal-dialog-centered "
          isOpen={addEspeceModal}
          toggle={() => {
            setAddEspeceModal(false);
            resteForm();
          }}
        >
          <ModalHeader
            toggle={() => {
              setAddEspeceModal(false);
              resteForm();
            }}
          >
            Ajouter des espèces
          </ModalHeader>
          <form onSubmit={handleSubmit(createTransaction)}>
            <ModalBody>
              <div className="content-form-block">
                <Row>
                  <Col md={12}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="label">Libellé * </Label>
                      <Input
                        id="label"
                        innerRef={labelRef}
                        {...label}
                        type="text"
                        className="form-secondary"
                        onChange={() => {
                          setErrorMessage(null);
                        }}
                      />
                      <span className="icon icon-secondary ">
                        {/* <img src={MoneyIconBlue} alt="icon" /> */}
                        {<ReactSVG src={MoneyIconBlue} />}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="date">Date * </Label>
                      <Controller
                        control={control}
                        name="date"
                        render={({field}) => (
                          <DatePicker
                            onChange={(date: any) => field.onChange(date)}
                            selected={
                              field.value ? new Date(field.value) : null
                            }
                            className="form-control form-secondary"
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />

                      <span className="icon icon-secondary ">
                        {/* <img src={CalenderIconBlue} alt="icon" /> */}
                        {<ReactSVG src={CalenderIconBlue} />}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="date">Catégorie</Label>
                      <Controller
                        name="category"
                        control={control}
                        render={({field}) => (
                          <Select
                            {...field}
                            options={inputSubCategs
                              .filter(
                                (elt) =>
                                  ![
                                    "IN-017",
                                    "IN-016",
                                    "IN-019",
                                    "IN-022",
                                  ].includes(elt.key)
                              )
                              .reduce(
                                (acc, curr) => [
                                  ...acc,
                                  {value: curr.id, label: curr.name},
                                ],
                                [] as {value: string; label: string}[]
                              )}
                            closeMenuOnSelect={true}
                            classNamePrefix="select"
                            className="custom-select form-secondary"
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="amount">Montant * </Label>
                      <Input
                        id="amount"
                        innerRef={amountRef}
                        {...amount}
                        type="number"
                        onWheel={(e) => (e.target as any).blur()}
                        step={0.01}
                        className="form-secondary"
                        onChange={(e) => {
                          if (errorMessage) {
                            setErrorMessage(null);
                          }
                        }}
                        min={0}
                      />
                      <span className="icon icon-secondary ">
                        {/* <img src={packMoneyIcons} alt="icon" /> */}
                        {<ReactSVG src={packMoneyIcons} />}
                      </span>
                    </FormGroup>
                  </Col>
                  {billSuggestionsChecker("enc").length > 0 && (
                    <Col md={12}>
                      <FormGroup>
                        <span className="icon-invoice-download">
                          <FaFileInvoice /> Ajoutez une ou plusieurs factures{" "}
                          <strong
                            onClick={async () => {
                              try {
                                if (
                                  !getValues("amount") ||
                                  getValues("amount") === 0
                                ) {
                                  toast.dismiss();
                                  toast.warning(
                                    `Vous devez ajouter un montant valide .`,
                                    {
                                      position: "bottom-right",
                                      autoClose: 3000,
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                    }
                                  );
                                  return;
                                }
                                setBillSuggestModal(true);
                              } catch (error: any) {
                                ErrorLogger(
                                  "updating a transaction's document",
                                  error
                                );
                              }
                            }}
                          >
                            ici
                          </strong>
                        </span>
                      </FormGroup>
                    </Col>
                  )}
                  <Col md={12}>
                    <FormGroup>
                      <FilePicker
                        className="form-file file-secondary "
                        onChange={handleDocumentsChange}
                        state={documents}
                        renderType={"array"}
                        fileStagedDelete={(file: {name: any}) =>
                          setDocuments((prevState: any) => {
                            return prevState.filter(
                              (elt: {name: any}) =>
                                elt.name !== (file as any).name
                            );
                          })
                        }
                        setUrl={setUrl}
                        setViewModal={setViewModal}
                        isMultiple={true}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {errorMessage?.type === "invalid_form" && (
                  <Alert color="danger">{errorMessage?.message}</Alert>
                )}
                {errorMessage?.type === "undexpected_issue" && (
                  <Alert color="danger">{errorMessage?.message}</Alert>
                )}
              </div>
            </ModalBody>

            <ModalFooter>
              <Button
                color="secondary"
                outline
                onClick={() => {
                  setAddEspeceModal(false);
                  resteForm();
                }}
                type="button"
                disabled={createLoading}
              >
                Annuler
              </Button>
              <Button
                color="secondary"
                type="submit"
                onClick={() => {
                  setValue("modalType", "positive");
                }}
                disabled={createLoading}
              >
                {createLoading ? (
                  <Spinner color="light" type="border" size={"sm"}>
                    Loading...
                  </Spinner>
                ) : (
                  "Ajouter"
                )}
              </Button>
            </ModalFooter>
          </form>
        </Modal>

        {/*
        bill suggestion for transaction
        */}
        <Modal
          className="modal-primary modal-dialog-centered "
          isOpen={billSuggestModal}
          toggle={() => {
            setBillSuggestModal(false);
            handleBillPageChange(1);
            setBillAddCustom(false);
          }}
        >
          <ModalHeader
            toggle={() => {
              setBillSuggestModal(false);
              handleBillPageChange(1);
              setBillAddCustom(false);
            }}
          >
            Justificatif
          </ModalHeader>

          <ModalBody>
            <div className="content-form-block">
              <div className="text-center">
                Vous pouvez ajouter une ou plusieurs factures correspondant à
                votre transaction.
              </div>
              <DataTable
                columns={billColumns}
                data={billSuggestions.filter((elt) => {
                  let transactions = elt.transactions;
                  if (transactions && transactions.length > 0) {
                    let parentIndex = transactions.findIndex(
                      (tr) =>
                        tr.relatedToFactureType ===
                        InvoiceTransactionTypes.Parent
                    );

                    if (parentIndex > -1) {
                      if (addEspeceModal) {
                        if (
                          transactions[parentIndex].parentType ===
                            InvoiceTransactionParentTypes.Enc ||
                          transactions[parentIndex].parentType ===
                            InvoiceTransactionParentTypes.ManualEnc
                        ) {
                          return elt;
                        } else {
                          return;
                        }
                      } else if (addNoteFraisModal) {
                        if (
                          transactions[parentIndex].parentType ===
                          InvoiceTransactionParentTypes.Dec
                        ) {
                          return elt;
                        } else {
                          return;
                        }
                      }
                    } else {
                      return elt;
                    }
                  }
                })}
                pagination
                paginationComponent={(props) => {
                  const customProps = {...props, color: "primary"};
                  return <BootyPagination {...customProps} />;
                }}
                paginationServer
                paginationTotalRows={totalBillRows}
                onChangePage={handleBillPageChange}
                paginationDefaultPage={currentBillPage}
                paginationPerPage={10}
                noDataComponent={<p>Il n'y a aucun data à afficher</p>}
              />
            </div>
          </ModalBody>
        </Modal>

        {/*add note frais*/}
        <Modal
          className="modal-primary modal-dialog-centered"
          isOpen={addNoteFraisModal}
          toggle={() => {
            setAddNoteFraisModal(false);
            resteForm();
          }}
        >
          <ModalHeader
            toggle={() => {
              setAddNoteFraisModal(false);
              resteForm();
            }}
          >
            Ajouter une note de frais
          </ModalHeader>
          <form onSubmit={handleSubmit(createTransaction)}>
            <ModalBody>
              <div className="content-form-block">
                <Row>
                  <Col md={12}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="label">Libellé * </Label>
                      <Input
                        id="label"
                        innerRef={labelRef}
                        {...label}
                        type="text"
                        className="form-primary"
                        onChange={() => {
                          setErrorMessage(null);
                        }}
                      />
                      <span className="icon icon-primary ">
                        {/* <img src={MoneyIconGreen} alt="icon" /> */}
                        {<ReactSVG src={MoneyIconGreen} />}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="date">Date * </Label>
                      <Controller
                        control={control}
                        name="date"
                        render={({field}) => (
                          <DatePicker
                            onChange={(date: any) => field.onChange(date)}
                            selected={
                              field.value ? new Date(field.value) : null
                            }
                            className="form-control form-primary"
                            locale="fr"
                            dateFormat="dd/MM/yyyy"
                          />
                        )}
                      />
                      <span className="icon icon-primary ">
                        {/* <img src={CalenderIconGreen} alt="icon" /> */}
                        {<ReactSVG src={CalenderIconGreen} />}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col md={12}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="date">Catégorie</Label>
                      <Controller
                        name="category"
                        control={control}
                        render={({field}) => (
                          <Select
                            {...field}
                            options={outputSubCategs.reduce(
                              (acc, curr) => [
                                ...acc,
                                {value: curr.id, label: curr.name},
                              ],
                              [] as {value: string; label: string}[]
                            )}
                            closeMenuOnSelect={true}
                            classNamePrefix="select"
                            className="custom-select form-primary"
                          />
                        )}
                      />
                    </FormGroup>
                  </Col>
                  {outputSubCategs.findIndex(
                    (elt) =>
                      elt.id === _category?.value && elt.key === CarbKeys.OUT028
                  ) > -1 && (
                    <Col md={12}>
                      <FormGroup className="form-icon icon-start">
                        <Label for="date">Quel est le type de véhicule ?</Label>
                        <Controller
                          name="carbVehType"
                          control={control}
                          render={({field}) => (
                            <Select
                              {...field}
                              options={[
                                {
                                  label: "Véhicule utilitaire",
                                  value: CarbVehTypes.Utility,
                                },
                                {
                                  label: "Véhicule de tourisme",
                                  value: CarbVehTypes.Tourisme,
                                },
                              ]}
                              closeMenuOnSelect={true}
                              classNamePrefix="select"
                              className="custom-select form-primary"
                            />
                          )}
                        />
                      </FormGroup>
                    </Col>
                  )}
                  <Col md={12}>
                    <FormGroup className="form-icon icon-start">
                      <Label for="amount">Montant * </Label>
                      <Input
                        id="amount"
                        innerRef={amountRef}
                        {...amount}
                        type="number"
                        onWheel={(e) => (e.target as any).blur()}
                        step={0.01}
                        className="form-primary"
                        onChange={() => {
                          setErrorMessage(null);
                        }}
                        min={0}
                      />
                      <span className="icon icon-primary ">
                        {/* <img src={MoneySacIconGreen} alt="icon" /> */}
                        {<ReactSVG src={MoneySacIconGreen} />}
                      </span>
                    </FormGroup>
                  </Col>
                  {billSuggestionsChecker("dec").length > 0 && (
                    <Col md={12}>
                      <FormGroup>
                        <span className="icon-invoice-download primary">
                          <FaFileInvoice /> Ajoutez une ou plusieurs factures{" "}
                          <strong
                            onClick={async () => {
                              try {
                                if (
                                  !getValues("amount") ||
                                  getValues("amount") === 0
                                ) {
                                  toast.dismiss();
                                  toast.warning(
                                    `Vous devez ajouter un montant valide .`,
                                    {
                                      position: "bottom-right",
                                      autoClose: 3000,
                                      hideProgressBar: false,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                    }
                                  );
                                  return;
                                }
                                setBillSuggestModal(true);
                              } catch (error: any) {
                                ErrorLogger(
                                  "updating a transaction's document",
                                  error
                                );
                              }
                            }}
                          >
                            ici
                          </strong>
                        </span>
                      </FormGroup>
                    </Col>
                  )}
                  <Col md={12}>
                    <FilePicker
                      className="form-file file-primary "
                      onChange={handleDocumentsChange}
                      state={documents}
                      renderType={"array"}
                      fileStagedDelete={(file: {name: any}) =>
                        setDocuments((prevState: any) => {
                          return prevState.filter(
                            (elt: {name: any}) =>
                              elt.name !== (file as any).name
                          );
                        })
                      }
                      setUrl={setUrl}
                      setViewModal={setViewModal}
                      isMultiple={true}
                    />
                  </Col>
                </Row>
                {errorMessage?.type === "invalid_form" && (
                  <Alert color="danger">{errorMessage?.message}</Alert>
                )}
                {errorMessage?.type === "undexpected_issue" && (
                  <Alert color="danger">{errorMessage?.message}</Alert>
                )}
              </div>
            </ModalBody>

            <ModalFooter>
              <Button
                color="primary"
                outline
                onClick={() => {
                  setAddNoteFraisModal(false);
                  resteForm();
                }}
                type="button"
                disabled={createLoading}
              >
                Annuler
              </Button>
              <Button
                color="primary"
                type="submit"
                onClick={() => {
                  setValue("modalType", "negative");
                }}
                disabled={createLoading}
              >
                {createLoading ? (
                  <Spinner color="light" type="border" size={"sm"}>
                    Loading...
                  </Spinner>
                ) : (
                  "Ajouter"
                )}
              </Button>
            </ModalFooter>
          </form>
        </Modal>

        {/*archive all transactions*/}
        <Modal
          className="modal-warning modal-dialog-centered "
          isOpen={archiveAllErrorModal}
          toggle={() => {
            setArchiveAllErrorModal(false);
            setArchiveAllInvalidStatus(false);
            setArchiveAllInvaliddocs(false);
            setArchiveAllSpecialInvaliddocs(false);
            setErrorMessage(null);
          }}
        >
          <ModalHeader
            toggle={() => {
              setArchiveAllErrorModal(false);
              setArchiveAllInvalidStatus(false);
              setArchiveAllInvaliddocs(false);
              setArchiveAllSpecialInvaliddocs(false);
              setErrorMessage(null);
            }}
          >
            Archiver tout
          </ModalHeader>
          <ModalBody>
            <div className="content-form-block archive_all__errors text-center">
              {archiveAllInvalidStatus && (
                <p>
                  Certaines des transactions ne sont pas valides pour
                  l'archivage
                </p>
              )}
              {archiveAllInvalidDocs && (
                <p>
                  Certaines des transactions n'ont pas de document
                  jsutificatifs, voulez-vous continuer ?
                </p>
              )}
              {archiveAllSepcialInvalidDocs && (
                <p>
                  Un justificatif doit être fourni pour ce type d’opérations. En
                  cas de contrôle, vous devrez être en mesure de fournir toutes
                  les pièces nécessaires.
                </p>
              )}
              {errorMessage?.type === "undexpected_issue" && (
                <Alert color="danger">{errorMessage?.message}</Alert>
              )}
            </div>
          </ModalBody>
          {!archiveAllInvalidStatus && (
            <ModalFooter>
              <Button
                color="warning"
                onClick={async () => await archiveAllTransactions(context!, 1)}
              >
                {archiveAllSepcialInvalidDocs ? "J’ai compris" : "Archiver"}
              </Button>{" "}
              <Button
                color="warning"
                outline
                onClick={() => {
                  setArchiveAllErrorModal(false);
                  setArchiveAllInvalidStatus(false);
                  setArchiveAllInvaliddocs(false);
                  setArchiveAllSpecialInvaliddocs(false);
                  setErrorMessage(null);
                }}
              >
                Annuler
              </Button>
            </ModalFooter>
          )}
        </Modal>

        <FileViewer
          url={url!}
          setUrl={setUrl}
          viewModal={viewModal}
          setViewModal={setViewModal}
        />
      </div>
    </div>
  );
};

export default MesTransactions;
