import React from "react";
import {Card, Modal, ModalBody, ModalHeader} from "reactstrap";

import {IBridgeAccount, IRelatedAccount} from "../../../../interfaces";
import GestionBridgeAccount from "../../../AccountsChoice/GestionBridgeAccount/GestionBridgeAccount";
import {useSelector} from "react-redux";

type Props = {
  openIbanModal: boolean;
  handelModal: () => void;
  getBridgeAcoounts: () => Promise<void>;
  ibans: IBridgeAccount[];
  clientId: string;
};

const CompteGestionIbanModal = ({
  openIbanModal,
  handelModal,
  getBridgeAcoounts,
  ibans,
  clientId,
}: Props) => {
  const companiesList = useSelector(
    (state: {root: object; user: Record<string, unknown>}) =>
      state.user.userCompanies
  ) as Array<Record<string, string>>;
  return (
    <Modal
      className="modal-secondary modal-dialog-centered"
      isOpen={openIbanModal}
      toggle={handelModal}
    >
      <ModalHeader toggle={handelModal}>Gestion Iban</ModalHeader>
      <ModalBody>
        <div className="container">
          <GestionBridgeAccount
            getBridgeAccounts={getBridgeAcoounts}
            ibans={ibans}
            handelModal={handelModal}
            clientId={clientId}
            companiesList={companiesList}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CompteGestionIbanModal;
